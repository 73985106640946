import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../fbConfig';
import {
  ISession,
  ESessionOpenTarget,
  ESessionActivationType,
  ISchedule,
} from '../../interfaces';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';
import CreateSessionForm from '../CreateSessionForm/CreateSessionForm';

import SessionTableRow from './SessionTableRow';
import useTimeTrigger from '../../hooks/useTimeTrigger';
import { SESSIONS_COLL_ID } from '../../constants';
import { stickyColumnSx } from '../../styleUtils';
import SessionsTable from './SessionsTable';
import { useLocalStorage } from '@mantine/hooks';

export const isNowWithinSchedule = (schedule: ISchedule, nowS: number) => {
  return nowS >= schedule.start && nowS < schedule.end;
};

interface Obj<T> {
  [key: string]: T;
}

function mapObj<TValue, TResult>(
  obj: Obj<TValue>,
  valSelector: (val: TValue, obj: Obj<TValue>) => TResult
) {
  const ret = {} as Obj<TResult>;
  for (const key of Object.keys(obj)) {
    const retVal = valSelector.call(null, obj[key], obj);
    ret[key] = retVal;
  }
  return ret;
}

const getOpenSessions = (sessions: ISession[], nowS: number) =>
  Object.values(ESessionOpenTarget)
    .map(openTarget => [
      openTarget,
      sessions
        .filter(ses =>
          ses[openTarget].type === ESessionActivationType.onPrompt
            ? ses[openTarget].open
            : isNowWithinSchedule(ses[openTarget].open as ISchedule, nowS)
        )
        .map(ses => ses.id),
    ])
    .reduce(
      (acc, [openTarget, val]) => ({
        ...acc,
        [openTarget as ESessionOpenTarget]: val,
      }),
      {} as { [openTarget in ESessionOpenTarget]: string[] }
    );

function SessionsList() {
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [createSessionOpen, setCreateSessionOpen] = useState(false);

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      collection(db, SESSIONS_COLL_ID),
      snaps => {
        setSessions(
          snaps.docs.map(doc => ({ ...doc.data(), id: doc.id } as ISession))
        );
      },
      err => {
        console.error('ERROR WHEN LISTENING TO SESSIONS', err);
      }
    );
    return () => {
      clearOnSnapshot();
    };
  }, []);

  const [favoriteSessionIds, setFavoriteSessionIds] = useLocalStorage<string[]>(
    {
      key: 'favorite-session-ids',
      defaultValue: [],
    }
  );

  return (
    <>
      <SessionsTable
        sessions={sessions.filter(session =>
          favoriteSessionIds.includes(session.id)
        )}
        favoriteSessions
        onFavoriteClick={sessionId =>
          setFavoriteSessionIds(prev =>
            prev.filter(favoriteSessionId => favoriteSessionId != sessionId)
          )
        }
      />
      <SessionsTable
        sessions={sessions.filter(
          session => !favoriteSessionIds.includes(session.id)
        )}
        favoriteSessions={false}
        onFavoriteClick={sessionId =>
          setFavoriteSessionIds(prev => prev.concat(sessionId))
        }
      />
      <Fab
        color="primary"
        sx={{ position: 'fixed', top: 30, right: 30 }}
        onClick={() => {
          setCreateSessionOpen(true);
        }}
      >
        <Add />
      </Fab>
      <CreateSessionForm
        open={createSessionOpen}
        setOpen={setCreateSessionOpen}
        existingIds={sessions.map(({ id }) => id)}
      />
    </>
  );
}

export default SessionsList;
