import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ReactNode } from 'react';
import PickerElement from './PickerElement';

interface IAdminPickerCardProps {
  elements: {
    id: string;
    title: string;
    subTitle: string;
    imgUrl?: string;
    disabled?: boolean;
    content: ReactNode;
  }[];
  selectedElementIds: string[];
  onSelectionChange: (newSelectedElementIds: string[]) => void;
  onConfirm: () => void;
  onCancel: () => void;
}

function ElementsPicker(props: IAdminPickerCardProps) {
  const {
    elements,
    selectedElementIds,
    onSelectionChange,
    onConfirm,
    onCancel,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: '100%', width: '100%', my: 'auto', py: 3 }}
      alignItems="center"
    >
      <Grid
        container
        columnSpacing={2}
        rowSpacing={4}
        justifyContent="space-around"
        alignItems="stretch"
        justifyItems="stretch"
      >
        {elements.map(({ id, title, subTitle, imgUrl, disabled, content }) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            xl={1}
            key={id}
            onClick={() => {
              if (selectedElementIds.includes(id))
                onSelectionChange(selectedElementIds.filter(elm => elm !== id));
              else onSelectionChange(selectedElementIds.concat(id));
            }}
            sx={{
              pointerEvents: disabled ? 'none' : 'auto',
            }}
          >
            <PickerElement
              selected={selectedElementIds.includes(id)}
              imgUrl={imgUrl}
              title={title}
              subTitle={subTitle}
              subSubTitle={id}
              disabled={disabled}
              secondaryActionType={'delete'}
              secondaryAction={() => {}}
              content={content}
            />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" gap={3}>
        <Button variant="outlined" onClick={onCancel}>
          Annulla
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Conferma selezione
        </Button>
      </Box>
    </Box>
  );
}

export default ElementsPicker;
