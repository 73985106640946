import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { ISchedule } from '../../interfaces';

interface IScheduleDisplayProps {
  schedule: ISchedule;
}

function ScheduleDisplay(props: IScheduleDisplayProps) {
  const { schedule } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography noWrap>
        {format(fromUnixTime(schedule.start), 'dd/MM/yyyy - HH:mm')}
      </Typography>
      <KeyboardArrowDownIcon />
      <Typography noWrap>
        {format(fromUnixTime(schedule.end), 'dd/MM/yyyy - HH:mm')}
      </Typography>
    </Box>
  );
}

export default ScheduleDisplay;
