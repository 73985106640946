import { IconButton, SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ToolTip from '@mui/material/Tooltip';
import { CSSProperties, PropsWithChildren, useState } from 'react';

interface ICustomButtonProps {
  onClick: () => void;
  style?: CSSProperties;
  disabled?: boolean;
  shouldAskConfirm?: boolean;
  confirmMsg?: string;
  sx?: SxProps;
  variant?: 'text' | 'contained' | 'outlined';
  useIconButton?: boolean;
  sxColor?: string;
  toolTipTitle?: string;
}

function CustomButton(props: PropsWithChildren<ICustomButtonProps>) {
  const {
    onClick,
    disabled,
    shouldAskConfirm,
    confirmMsg,
    children,
    sx,
    variant,
    useIconButton,
    sxColor: color,
    toolTipTitle,
  } = props;
  const [confirmOpen, setConfirmOpen] = useState(false);

  const btnProps = {
    variant: variant,
    size: 'large' as 'large',
    onClick: shouldAskConfirm ? () => setConfirmOpen(true) : onClick,
    disabled,
    sx: { ...sx, color },
  };
  return (
    <>
      <Dialog
        open={confirmOpen}
        onClose={() => {
          setConfirmOpen(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {confirmMsg ?? 'Confermare?'}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmOpen(false);
            }}
            color="error"
          >
            Annulla
          </Button>
          <Button
            onClick={() => {
              setConfirmOpen(false);

              onClick();
            }}
            autoFocus
            color="success"
          >
            CONFERMA
          </Button>
        </DialogActions>
      </Dialog>
      <ToolTip title={toolTipTitle ?? ''}>
        {useIconButton ? (
          <IconButton {...btnProps}>{children}</IconButton>
        ) : (
          <Button {...btnProps}>{children}</Button>
        )}
      </ToolTip>
    </>
  );
}

export default CustomButton;
