import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { TCallStatus } from '../../hooks/useCallStatus';
import { Dispatch, SetStateAction } from 'react';

interface ICallStatusialogProps {
  callStatus: TCallStatus;
  setCallStatus: Dispatch<SetStateAction<TCallStatus>>;
  successMsg: string;
  onClose: () => void;
}

function CallStatusDialog(props: ICallStatusialogProps) {
  const { callStatus, setCallStatus, successMsg, onClose } = props;
  return (
    <Dialog
      open={callStatus !== 'none'}
      onClose={() => {
        if (callStatus !== 'pending') setCallStatus('none');
        onClose();
      }}
      sx={{ padding: 3 }}
    >
      <DialogTitle>
        {callStatus === 'pending' && <CircularProgress />}
        {callStatus === 'success' && (
          <Typography variant="body1" color="success">
            {successMsg}
          </Typography>
        )}
        {callStatus === 'error' && (
          <Typography variant="body1" color="error">
            C'è stato un errore, riprova
          </Typography>
        )}
      </DialogTitle>
      {callStatus !== 'pending' && (
        <DialogActions>
          <Button
            onClick={() => {
              setCallStatus('none');
              onClose();
            }}
            autoFocus
            color="info"
          >
            CHIUDI
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default CallStatusDialog;
