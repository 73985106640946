import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import CustomButton from '../misc/CustomButton';
import { useState } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../fbConfig';
import {
  ISession,
  ESessionOpenTarget,
  ESessionActivationType,
  ISchedule,
} from '../../interfaces';
import { Edit, Favorite } from '@mui/icons-material';
import TouchApp from '@mui/icons-material/TouchApp';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import SchedulePicker from '../CreateSessionForm/SchedulePicker';
import { getUnixTime } from 'date-fns';
import { isScheduleValid } from '../CreateSessionForm/ActivationTypeSelector';
import ScheduleDisplay from '../misc/ScheduleDisplay';
import { isNowWithinSchedule } from './SessionsTable';
import SessionActions from './SessionActions';
import { SESSIONS_COLL_ID } from '../../constants';
import { stickyColumnSx } from '../../styleUtils';

const SESSION_TYPE_TEXT: { [key in ESessionOpenTarget]: string } = {
  openAud: 'del pubblico',
  openNonAud: 'dei giudici e dei rappresentanti',
};

const updateSession = (sessionId: string, sessionUpd: Partial<ISession>) => {
  setDoc(doc(db, SESSIONS_COLL_ID, sessionId), sessionUpd, { merge: true })
    .then(() => {
      console.info('Session updated successfully');
    })
    .catch(err => {
      console.error('ERROR WHEN CHANGING SESSION STATE', err);
    });
};

const convertToOnPrompt = (
  openTarget: ESessionOpenTarget,
  session: ISession
) => {
  const shouldBeOpen = isNowWithinSchedule(
    session[openTarget].open as ISchedule,
    Date.now() / 1000
  );

  const newSession: ISession = {
    ...session,
    [openTarget]: {
      type: ESessionActivationType.onPrompt,
      open: shouldBeOpen,
    },
  };
  setDoc(doc(db, SESSIONS_COLL_ID, session.id), newSession)
    .then(() => {
      console.info('Activation type changed to onPrompt successfully');
    })
    .catch(err => {
      console.error('ERROR WHEN CHANGING ACTIVATION TYPE OF SESSION', err);
    });
};

interface ISessionTableRowProps {
  session: ISession;
  isSessionOpen: { [openTarget in ESessionOpenTarget]: boolean };
  otherSessionsOpen: { [openTarget in ESessionOpenTarget]: boolean };
  isGiftTicketsOpen: boolean;
  otherGiftTicketsOpen: boolean;
  isTicketsOpen: boolean;
  otherTicketsOpen: boolean;
  isFavorite: boolean;
  onFavoriteClick: () => void;
}

function SessionTableRow(props: ISessionTableRowProps) {
  const {
    session,
    isSessionOpen,
    otherSessionsOpen,
    isGiftTicketsOpen,
    otherGiftTicketsOpen,
    isTicketsOpen,
    otherTicketsOpen,
    isFavorite,
    onFavoriteClick,
  } = props;

  const [newScheduleDialogTarget, setNewScheduleDialogTarget] =
    useState<null | ESessionOpenTarget>(null);

  const [newScheduleSession, setNewScheduleSession] = useState<null | ISession>(
    null
  );
  const [newSchedule, setNewSchedule] = useState<ISchedule<Date | null>>({
    start: new Date(),
    end: new Date(),
  });

  const convertToOnSchedule = (
    openTarget: ESessionOpenTarget,
    session: ISession
  ) => {
    const newSession: ISession = {
      ...session,
      [openTarget]: {
        type: ESessionActivationType.onSchedule,
        open: {
          start: getUnixTime(newSchedule.start!),
          end: getUnixTime(newSchedule.end!),
        },
      },
    };
    setDoc(doc(db, SESSIONS_COLL_ID, session.id), newSession)
      .then(() => {
        setNewScheduleDialogTarget(null);
        setNewScheduleSession(null);
        console.info('Activation type changed to onSchedule successfully');
      })
      .catch(err => {
        console.error('ERROR WHEN CHANGING ACTIVATION TYPE OF SESSION', err);
      });
  };

  return (
    <>
      <Dialog
        open={newScheduleDialogTarget !== null && newScheduleSession !== null}
        onClose={() => {
          setNewScheduleDialogTarget(null);
        }}
      >
        <Stack m={3} gap={2}>
          <Typography variant="h5" color="primary" align="center">
            Seleziona la nuova programmazione per la votazione
          </Typography>
          <SchedulePicker
            schedule={newSchedule}
            onScheduleChange={newSched => {
              setNewSchedule(newSched);
            }}
          />
          <CustomButton
            variant="contained"
            onClick={() => {
              convertToOnSchedule(
                newScheduleDialogTarget!,
                newScheduleSession!
              );
            }}
            disabled={isScheduleValid(newSchedule)}
          >
            Conferma
          </CustomButton>
        </Stack>
      </Dialog>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        key={session.id}
      >
        <TableCell>{session.id}</TableCell>
        <TableCell>{session.description}</TableCell>
        {Object.values(ESessionOpenTarget).map(openTarget => {
          const openType = session[openTarget].type;
          const openVal = session[openTarget].open;
          const btnColor = 'primary';
          return (
            <TableCell align="center" key={openTarget}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  p: 1,
                  ...(isSessionOpen[openTarget]
                    ? {
                        borderRadius: 1,
                        outline: '3px solid lightgreen',
                        outlineOffset: '6px',
                      }
                    : {}),
                }}
              >
                {openType === ESessionActivationType.onPrompt ? (
                  <>
                    <CustomButton
                      shouldAskConfirm
                      sxColor={btnColor}
                      disabled={!openVal && otherSessionsOpen[openTarget]}
                      confirmMsg={`${
                        openVal ? 'Chiudere' : 'Aprire'
                      } la votazione ${SESSION_TYPE_TEXT[openTarget]}?`}
                      onClick={() => {
                        updateSession(session.id, {
                          [openTarget]: { type: 'onPrompt', open: !openVal },
                        });
                      }}
                      variant="outlined"
                      sx={{
                        width: '80%',
                        maxWidth: 120,
                        minWidth: 75,
                        color: btnColor,
                        borderColor: btnColor,
                      }}
                    >
                      {openVal ? 'Chiudi' : 'Apri'}
                    </CustomButton>
                  </>
                ) : (
                  <>
                    <ScheduleDisplay schedule={openVal as ISchedule} />
                  </>
                )}
                <Box display="flex" flexDirection="column">
                  <CustomButton
                    onClick={() => {
                      setNewScheduleDialogTarget(openTarget);
                      setNewScheduleSession(session);
                    }}
                    useIconButton
                    sxColor="primary.main"
                    toolTipTitle={
                      openType === ESessionActivationType.onPrompt
                        ? 'Programma'
                        : 'Modifica programmazione'
                    }
                  >
                    {openType === ESessionActivationType.onPrompt ? (
                      <QueryBuilder />
                    ) : (
                      <Edit />
                    )}
                  </CustomButton>
                  {openType === ESessionActivationType.onSchedule && (
                    <CustomButton
                      onClick={() => {
                        convertToOnPrompt(openTarget, session);
                      }}
                      confirmMsg='Confermi di convertire la votazione in "Ad attivazione"'
                      useIconButton
                      shouldAskConfirm
                      sxColor="primary.main"
                      toolTipTitle='Converti in "Ad attivazione"'
                    >
                      <TouchApp />
                    </CustomButton>
                  )}
                </Box>
              </Box>
            </TableCell>
          );
        })}
        <TableCell align="center">
          <CustomButton
            shouldAskConfirm
            sxColor="primary.main"
            disabled={!isTicketsOpen && otherTicketsOpen}
            confirmMsg={`${
              isTicketsOpen ? 'Chiudere' : 'Aprire'
            } la prenotazione per i biglietti?`}
            onClick={() => {
              updateSession(session.id, {
                ticketsOpen: !isTicketsOpen,
              });
            }}
            variant="outlined"
            sx={{
              width: '80%',
              maxWidth: 120,
              minWidth: 75,
              color: 'primary.main',
              borderColor: 'primary.main',
            }}
          >
            {isTicketsOpen ? 'Chiudi' : 'Apri'}
          </CustomButton>
        </TableCell>
        <TableCell align="center">
          <CustomButton
            shouldAskConfirm
            sxColor="primary.main"
            disabled={!isGiftTicketsOpen && otherGiftTicketsOpen}
            confirmMsg={`${
              isGiftTicketsOpen ? 'Chiudere' : 'Aprire'
            } la prenotazione per gli accrediti?`}
            onClick={() => {
              updateSession(session.id, {
                giftTicketsOpen: !isGiftTicketsOpen,
              });
            }}
            variant="outlined"
            sx={{
              width: '80%',
              maxWidth: 120,
              minWidth: 75,
              color: 'primary.main',
              borderColor: 'primary.main',
            }}
          >
            {isGiftTicketsOpen ? 'Chiudi' : 'Apri'}
          </CustomButton>
        </TableCell>
        <TableCell align="center" sx={{ ...stickyColumnSx('right') }}>
          <SessionActions
            session={session}
            onFavoriteClick={onFavoriteClick}
            isFavorite={isFavorite}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default SessionTableRow;
