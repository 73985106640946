import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {
  IGroup,
  IJudge,
  TOpenTargetActivateInfo,
  ESessionOpenTarget,
  ESessionActivationType,
  EScheduleLimits,
  ISchedule,
  VotingScheme,
  GroupTalentOrder,
} from '../../interfaces';
import CustomButton from '../misc/CustomButton';
import SessionRecap from '../SessionRecap';
import { OPEN_TARGET, DATE_PICKER_LABEL } from '../../constants';
import TouchApp from '@mui/icons-material/TouchApp';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import { Fragment } from 'react';

interface ICreateSessionRecapProps {
  onConfirm: () => void;
  groups: IGroup[];
  sessionDescription: string;
  judges: IJudge[];
  sessionId: string;
  activateInfos: TOpenTargetActivateInfo;
  votingScheme: VotingScheme;
  songsPerTalent: number | undefined;
  groupTalentOrder: GroupTalentOrder;
}

function CreateSessionRecap(props: ICreateSessionRecapProps) {
  const {
    groups,
    sessionDescription,
    sessionId,
    onConfirm,
    judges,
    activateInfos,
    votingScheme,
    songsPerTalent,
    groupTalentOrder,
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      py={2}
    >
      <Typography variant="h4" color="primary" align="center" my={2}>
        Riepilogo
      </Typography>
      <SessionRecap
        groups={groups}
        judges={judges}
        id={sessionId}
        description={sessionDescription}
        showCreds={false}
        votingScheme={votingScheme}
        songsPerTalent={songsPerTalent}
        groupTalentOrder={groupTalentOrder}
      />
      <Stack alignItems="center" mt={3} gap={1}>
        {Object.keys(activateInfos).map(oT => {
          const openTarget = oT as ESessionOpenTarget;
          return (
            <Fragment key={oT}>
              <Typography color="primary" my={1}>
                <b>Votazione {OPEN_TARGET[openTarget]}</b>
              </Typography>
              {activateInfos[openTarget].type ===
              ESessionActivationType.onPrompt ? (
                <Box display="flex" gap={1}>
                  <TouchApp />
                  <Typography>Ad attivazione</Typography>
                </Box>
              ) : (
                <Stack gap={1}>
                  <Box display="flex" justifyContent="center" gap={1}>
                    <QueryBuilder />
                    <Typography>Programmata</Typography>
                  </Box>
                  {Object.keys(activateInfos[openTarget].open).map(l => {
                    const limit = l as EScheduleLimits;
                    return (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography>
                          {DATE_PICKER_LABEL[limit]}: &ensp;
                        </Typography>
                        <Typography>
                          {format(
                            fromUnixTime(
                              (activateInfos[openTarget].open as ISchedule)[
                                limit
                              ]
                            ),
                            'dd/MM/yyyy - hh:mm'
                          )}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              )}
            </Fragment>
          );
        })}
      </Stack>
      <CustomButton
        onClick={onConfirm}
        variant="contained"
        shouldAskConfirm
        confirmMsg="Confermi di creare la votazione?"
        sx={{ mt: 3 }}
      >
        Crea Votazione
      </CustomButton>
    </Box>
  );
}

export default CreateSessionRecap;
