import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { IJudgeDoc } from '../../interfaces';
import { doc, runTransaction } from 'firebase/firestore';
import { db } from '../../fbConfig';
import { MNG_INFO_COLL_ID, JUDGES_LIST_DOC_ID } from '../../constants';

interface ICreateTalentFormProps {
  onClose: () => void;
  existingNames: string[];
}

function CreateJudgeForm(props: ICreateTalentFormProps) {
  const { onClose, existingNames } = props;
  const [name, setName] = useState('');

  const createJudge = () => {
    const judgeListRef = doc(db, MNG_INFO_COLL_ID, JUDGES_LIST_DOC_ID);
    runTransaction(db, t =>
      t.get(judgeListRef).then(snap => {
        const judgeListDoc = (snap.data() ?? { judges: {} }) as IJudgeDoc;
        judgeListDoc.judges = [...(judgeListDoc?.judges ?? []), name];
        t.set(judgeListRef, judgeListDoc);
      })
    )
      .then(() => {
        console.info('Judge created successfully!');
        onClose();
      })
      .catch(err => {
        console.error('ERROR WHEN CREATING NEW JUDGE', err);
      });
  };

  const nameAlreadyExists = existingNames.includes(name);

  return (
    <Box
      margin={2}
      display="flex"
      flexDirection="column"
      gap={2}
      alignItems="center"
    >
      <Typography color="primary">Crea un nuovo giudice</Typography>
      <TextField
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
        required
        label="Nome completo"
        id="name"
        error={nameAlreadyExists}
        helperText={nameAlreadyExists && 'Id già in uso'}
      />
      <Button
        variant="contained"
        disabled={!name || nameAlreadyExists}
        onClick={createJudge}
      >
        Crea giudice
      </Button>
    </Box>
  );
}

export default CreateJudgeForm;
