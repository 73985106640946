import { ITalent } from '../interfaces';
import { arrOfLen } from '../utils';
import TextTable from './TextTable';

type TalentsTableProps = { talents: ITalent[] };

function TalentsTable({ talents }: TalentsTableProps) {
  const songsPerTalent = Math.max(
    ...talents.map(tal => tal.songs?.length ?? 0)
  );
  return (
    <TextTable
      columnTitles={[
        'Id',
        'Nome',
        'Scuola',
        'Dominio scuola',
        ...arrOfLen(songsPerTalent === -Infinity ? 0 : songsPerTalent).map(
          (_, i) => `Canzone ${i + 1}`
        ),
      ]}
      rows={talents.map(
        ({ id: talId, name, schoolType, schoolName, songs, schoolDomain }) => [
          talId,
          name,
          `${schoolType} ${schoolName}`,
          schoolDomain ?? '-',
          ...(songs?.map(({ name: songName, author, isOriginal }) => (
            <>
              {songName}
              <br />
              {author}
              <br />
              {isOriginal ? 'Inedito' : 'Cover'}
            </>
          )) ?? []),
        ]
      )}
    />
  );
}

export default TalentsTable;
