import { useState } from 'react';

export type TCallStatus = 'none' | 'pending' | 'error' | 'success';

function useCallStatus(
  func: (args: any) => Promise<any>,
  args: any,
  additionalThen?: () => void,
  additionalCatch?: () => void
) {
  const [callStatus, setCallStatus] = useState<TCallStatus>('none');
  const call = () => {
    setCallStatus('pending');
    func(args)
      .then(res => {
        setCallStatus('success');
        if (additionalThen) additionalThen();
      })
      .catch(err => {
        setCallStatus('error');
        console.error('ERROR WHEN CREATING SESSION', err);
        if (additionalCatch) additionalCatch();
      });
  };
  return [call, callStatus, setCallStatus] as [
    () => void,
    TCallStatus,
    typeof setCallStatus
  ];
}

export default useCallStatus;
