import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { GroupTalentOrder, IGroup } from '../../interfaces';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton } from '@mui/material';

interface IJudgePercentageSelectProps {
  groups: IGroup[];
  onConfirm: (sortedGroups: GroupTalentOrder) => void;
  onBack: () => void;
}
function arraymove(arr: Array<any>, fromIndex: number, toIndex: number) {
  const newArr = [...arr];
  const element = newArr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
}

function TalentOrderSelector(props: IJudgePercentageSelectProps) {
  const { groups, onConfirm } = props;
  const [sortedGroups, setSortedGroups] = useState(groups);
  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      <Typography my={2}>
        Inserisci un moltiplicatore per ogni giudice
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxWidth={250}
        width="100%"
        mb={2}
      >
        {sortedGroups.map(({ name: groupName, talents }, groupIdx) => (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={2}
            justifyContent="space-between"
            width="100%"
            key={groupIdx}
          >
            <Typography>{groupName}</Typography>
            {talents.map(({ name: talName }, talIdx) => (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="space-between"
                width="100%"
                key={talIdx}
              >
                <IconButton
                  onClick={() => {
                    const newSortedGroups = [...sortedGroups];
                    newSortedGroups[groupIdx].talents = arraymove(
                      newSortedGroups[groupIdx].talents,
                      talIdx,
                      talIdx - 1
                    );
                    setSortedGroups(newSortedGroups);
                  }}
                  disabled={talIdx === 0}
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <Typography>{talName}</Typography>
                <IconButton
                  onClick={() => {
                    const newSortedGroups = [...sortedGroups];
                    newSortedGroups[groupIdx].talents = arraymove(
                      newSortedGroups[groupIdx].talents,
                      talIdx,
                      talIdx + 1
                    );
                    setSortedGroups(newSortedGroups);
                  }}
                  disabled={talIdx === talents.length - 1}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          onConfirm(
            sortedGroups.reduce(
              (acc, { name, talents }) => ({
                ...acc,
                [name]: talents.reduce(
                  (acc, { id }, i) => ({ ...acc, [id]: i }),
                  {}
                ),
              }),
              {} as GroupTalentOrder
            )
          );
        }}
      >
        Prosegui
      </Button>
    </Box>
  );
}

export default TalentOrderSelector;
