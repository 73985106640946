import { useState, useEffect } from 'react';
import { db, functions } from '../../fbConfig';
import { IEditSessionData, IGroup, ITalent } from '../../interfaces';
import { collection, onSnapshot } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TALENTS_COLL_ID } from '../../constants';
import { Button, Dialog, Fab, MenuItem, TextField } from '@mui/material';
import ElementsPicker from '../ElementsPicker';
import TalentsTable from '../TalentsTable';
import { httpsCallable } from 'firebase/functions';
import CreateTalentForm from '../CreateSessionForm/CreateTalentForm';
import AddIcon from '@mui/icons-material/Add';
interface IEditSessionProps {
  sessionId: string;
  groups: IGroup[];
  songsPerTalent: number;
}

function EditSession(props: IEditSessionProps) {
  const { sessionId, groups, songsPerTalent } = props;

  const [talents, setTalents] = useState<ITalent[] | undefined>(undefined);
  const [selectedTalentIds, setSelectedTalentIds] = useState<string[]>([]);

  const [targetGroupName, setTargetGroupName] = useState(groups[0].name);
  const [openTalentPicker, setOpenTalentPicker] = useState(false);
  const [openCreateTalent, setOpenCreateTalent] = useState(false);

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      collection(db, TALENTS_COLL_ID),
      giftTickestSnap => {
        const newTalents = giftTickestSnap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        })) as ITalent[];
        setTalents(newTalents);
      },
      err => {
        console.error('ERROR WHEN LISTENING TO GIFT TICKETS', err);
      }
    );
    return () => {
      clearOnSnapshot();
    };
  }, [sessionId]);

  const selectedTals =
    talents &&
    selectedTalentIds.length > 0 &&
    talents.filter(tal => selectedTalentIds.includes(tal.id));

  const addTalentsToSession = () => {
    if (targetGroupName && selectedTals) {
      const editSession = httpsCallable(functions, 'sessionMng-editSession');

      const editSessionData: IEditSessionData = {
        targetSessionId: sessionId,
        addTalents: {
          talentsToAdd: selectedTals,
          targetGroupName,
        },
      };

      editSession(editSessionData)
        .then(res => {
          console.info('Session edited successfully', res);
        })
        .catch(err => {
          console.error('SOMETHING WENT WRONG WHEN EDITIN SESSION', err);
        });
    }
  };
  const allTalentIdsInGroup = groups
    .flatMap(group => group.talents)
    .map(tal => tal.id);
  return (
    <Box
      margin={3}
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap={2}
    >
      <Box>
        <Typography color="primary" variant="h5" align="center">
          Aggiungi un talento alla sessione
        </Typography>
        <Typography variant="h5" align="center" m={1}>
          {sessionId}
        </Typography>
      </Box>
      <TextField
        select
        value={targetGroupName}
        onChange={e => {
          setTargetGroupName(e.target.value);
        }}
        label="Girone da modificare"
        fullWidth
      >
        {groups.map((group, groupIdx) => (
          <MenuItem key={groupIdx} value={group.name}>
            {group.name}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="outlined"
        onClick={() => {
          setOpenTalentPicker(true);
        }}
      >
        Seleziona talenti da aggiungere
      </Button>
      {selectedTals && <TalentsTable talents={selectedTals} />}
      <Button
        variant="contained"
        onClick={addTalentsToSession}
        disabled={selectedTalentIds.length === 0}
      >
        Aggiungi talenti selezionati
      </Button>
      <Dialog
        open={openTalentPicker}
        onClose={() => {
          setOpenTalentPicker(false);
        }}
        fullScreen
      >
        {talents && (
          <ElementsPicker
            elements={talents
              .filter(tal => !allTalentIdsInGroup.includes(tal.id))
              .map(({ id, name, schoolType, schoolName, songs }) => ({
                id,
                title: name,
                subTitle: `${schoolType} ${schoolName}`,
                content: songs && (
                  <Typography variant="body2">
                    Canzoni: {songs.length}
                    <br />
                    <br />
                    {songs.map(({ name }) => (
                      <>
                        - {name}
                        <br />
                      </>
                    ))}
                    <br />
                  </Typography>
                ),
              }))}
            selectedElementIds={selectedTalentIds}
            onSelectionChange={newSelectedIds => {
              setSelectedTalentIds(newSelectedIds);
            }}
            onConfirm={() => {
              setOpenTalentPicker(false);
            }}
            onCancel={() => {
              setSelectedTalentIds([]);
              setOpenTalentPicker(false);
            }}
          />
        )}
        <Fab
          color="primary"
          variant="extended"
          sx={{ position: 'fixed', right: 30, bottom: 30, zIndex: 3 }}
          onClick={() => {
            setOpenCreateTalent(true);
          }}
        >
          Nuovo talento
          <AddIcon sx={{ ml: 1 }} />
        </Fab>

        <Dialog
          open={openCreateTalent}
          onClose={() => {
            setOpenCreateTalent(false);
          }}
        >
          <CreateTalentForm
            existingIds={groups
              .flatMap(group => group.talents)
              .map(tal => tal.id)}
            onCreationSuccess={() => {}}
            songsPerTalent={songsPerTalent}
          />
        </Dialog>
      </Dialog>
    </Box>
  );
}

export default EditSession;
