import { useState } from 'react';

type UseStepperArgs = { totalSteps?: number; initalValue?: number };

function useStepper(
  args?: UseStepperArgs
): [number, () => void, () => void, () => void] {
  const { totalSteps, initalValue } = args ?? {
    totalSteps: undefined,
    initalValue: undefined,
  };
  const [step, setStep] = useState(initalValue ?? 0);
  const stepBack = () => {
    if (step >= 0) {
      setStep(step - 1);
    }
  };

  const stepForward = () => {
    if (totalSteps === undefined || step <= totalSteps - 1) {
      setStep(step + 1);
    }
  };
  const resetStep = () => {
    setStep(0);
  };

  return [step, stepBack, stepForward, resetStep];
}
export default useStepper;
