import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { useState, useEffect } from 'react';
import { db } from '../../fbConfig';
import {
  IGiftTicketRequest,
  IFbMailService,
  ERequestStatus,
  TGiftTypes,
  GiftTypesHumanReadable,
} from '../../interfaces';
import CustomButton from '../misc/CustomButton';
import { collection, doc, onSnapshot, writeBatch } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccordionList from '../Accordion';
import { GIFT_TICKETS_COLL_ID, SESSIONS_COLL_ID } from '../../constants';

const REQ_TYPES = Object.values(TGiftTypes);

const REQ_STATUS_DISPLAY: {
  [key in ERequestStatus]: { sxColor: string; text: string };
} = {
  accepted: { sxColor: 'success.main', text: 'Accettate' },
  pending: { sxColor: 'warning.light', text: 'In attesa' },
  rejected: { sxColor: 'error.main', text: 'Rifiutate' },
};

const buildAcceptMessageHtml = (ticketsCount: string | undefined) => {
  // let htmlMsg = `Buongiorno! <br/>Abbiamo <b>accettato</b> la richiesta di accredito per `;
  // if (type === 'Giuria') {
  //   htmlMsg = `${htmlMsg} <b>${name} ${lastName}</b>`;
  // } else if (type === 'Stampa') {
  //   htmlMsg = `${htmlMsg} <b>${headLine}</b>`;
  // } else if (type === 'Ospiti, omaggi & Give Away') {
  //   htmlMsg = `${htmlMsg} <b>${name} ${lastName} per ${ticketsCount} ${
  //     Number(ticketsCount) > 1 ? 'biglietti' : 'biglietto'
  //   }</b>`;
  // }
  // htmlMsg = `${htmlMsg}.<br/>Potrà richiedere i biglietti all'ingresso dell'evento, presso lo sportello "accrediti".<br/>Per qualsiasi comunicazione le chiediamo di scrivere ad accrediti@gvnetwork.it .<br/><br/><i>Grazie per l'attenzione<br/>A presto<br/>Il team di Genvision</i>`;
  const htmlMsg = `Ciao!<br/>Abbiamo visionato la tua richiesta di accreditamento per la serata Genvision XX23, e abbiamo approvato la tua richiesta per un totale di ${
    !ticketsCount || ticketsCount === ''
      ? 'un accesso'
      : `${ticketsCount} accessi`
  } omaggio.<br/>Li potrai ritirare entro le ore 20:00 all'ingresso dell'evento, presso lo sportello "accrediti".<br/>Per qualsiasi comunicazione ti chiediamo di scrivere ad <a href="mailto:accrediti@gvnetwork.it">accrediti@gvnetwork.it</a>. <br/><br/>Grazie e a presto,<br/>Lo staff di Genvision`;

  return htmlMsg;
};

const buildRefuseMessageHtml = () => {
  // let htmlMsg = `Buongiorno! <br/>Abbiamo <b>declinato</b> la richiesta di accredito per `;
  // if (type === 'Giuria') {
  //   htmlMsg = `${htmlMsg} <b>${name} ${lastName}</b>`;
  // } else if (type === 'Stampa') {
  //   htmlMsg = `${htmlMsg} <b>${headLine}</b>`;
  // } else if (type === 'Ospiti, omaggi & Give Away') {
  //   htmlMsg = `${htmlMsg} <b>${name} ${lastName} per ${ticketsCount} ${
  //     Number(ticketsCount) > 1 ? 'biglietti' : 'biglietto'
  //   }</b>`;
  // }
  // htmlMsg = `${htmlMsg}.<br/>Per ulteriori informazioni le chiediamo di contattare l'associazione scrivendo a matteo.altamura@gvnetwork.it .<br/><br/><i>Grazie per l'attenzione<br/>A presto<br/>Il team di Genvision</i>`;

  const htmlMsg = `Ciao!<br/>Abbiamo visionato la tua richiesta di accreditamento per la serata Genvision XX23, e purtroppo abbiamo constatato di non poterla approvare.<br/>  Ti invitiamo ad assicurarti al più presto i tuoi posti dentro al teatro tramite il sito <a href="mailto:tickets.genvision.it">tickets.genvision.it</a>, ci auguriamo di trovarti con noi il 30 Maggio!<br/><br/>Grazie e buona serata,<br/>Lo staff di Genvision`;

  return htmlMsg;
};

interface IGiftTIcketsManagerProps {
  sessionId: string;
}
const REQUEST_STATUSES = Object.values(ERequestStatus);
const computeTotalGIftTickets = (giftTickets: IGiftTicketRequest[]) =>
  giftTickets.reduce((acc, req) => acc + (Number(req.ticketsCount) ?? 1), 0);

function GiftTicketsManager(props: IGiftTIcketsManagerProps) {
  const { sessionId } = props;

  const [giftTickets, setGiftTickets] = useState<IGiftTicketRequest[]>([]);

  useEffect(() => {
    const clearOnSnapshot = onSnapshot(
      collection(db, SESSIONS_COLL_ID, sessionId, GIFT_TICKETS_COLL_ID),
      giftTickestSnap => {
        const newGiftTickets = giftTickestSnap.docs.map(doc => ({
          ...doc.data(),
          email: doc.id,
        })) as IGiftTicketRequest[];
        setGiftTickets(newGiftTickets);
      },
      err => {
        console.error('ERROR WHEN LISTENING TO GIFT TICKETS', err);
      }
    );
    return () => {
      clearOnSnapshot();
    };
  }, [sessionId]);

  const acceptRequest = ({
    email,
    type,
    ticketsCount,
    name,
  }: IGiftTicketRequest) => {
    const acceptReqBatch = writeBatch(db);

    const reqToAccDocRef = doc(
      db,
      SESSIONS_COLL_ID,
      sessionId,
      GIFT_TICKETS_COLL_ID,
      email!
    );

    acceptReqBatch.set(
      reqToAccDocRef,
      { status: ERequestStatus.accepted },
      { merge: true }
    );

    const emailSendDocRef = doc(collection(db, 'mails'));
    const mailServiceDoc: IFbMailService = {
      to: [email!],
      message: {
        subject: 'Genvision - Conferma richiesta di accredito ',
        html: buildAcceptMessageHtml(ticketsCount),
      },
    };
    acceptReqBatch.set(emailSendDocRef, mailServiceDoc);
    acceptReqBatch
      .commit()
      .then(() => {
        console.info('Request succefully accepted');
      })
      .catch(err => {
        console.error('ERROR WHEN ACCEPTING A REQUEST', err);
      });
  };

  const rejectRequest = ({
    email,
    type,
    ticketsCount,
    name,
  }: IGiftTicketRequest) => {
    const acceptReqBatch = writeBatch(db);

    const reqToAccDocRef = doc(
      db,
      SESSIONS_COLL_ID,
      sessionId,
      GIFT_TICKETS_COLL_ID,
      email!
    );

    acceptReqBatch.set(
      reqToAccDocRef,
      { status: ERequestStatus.rejected },
      { merge: true }
    );

    const emailSendDocRef = doc(collection(db, 'mails'));
    const mailServiceDoc: IFbMailService = {
      to: [email!],
      message: {
        subject: 'Genvision - Rifiuto richiesta di accredito ',
        html: buildRefuseMessageHtml(),
      },
    };

    acceptReqBatch.set(emailSendDocRef, mailServiceDoc);
    acceptReqBatch
      .commit()
      .then(() => {
        console.info('Request succefully accepted');
      })
      .catch(err => {
        console.error('ERROR WHEN ACCEPTING A REQUEST', err);
      });
  };
  const totalGiftTicketsRequests = giftTickets.length;
  const totalRequestedGiftTickets = computeTotalGIftTickets(giftTickets);

  return (
    <Box margin={3}>
      <Typography color="primary" variant="h5" align="center" mb={2}>
        Accrediti
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" align="center">
          Richieste:
        </Typography>
        <Box>
          {totalGiftTicketsRequests} (
          {REQUEST_STATUSES.map((status, i) => (
            <Typography
              variant="body1"
              display="inline"
              color={REQ_STATUS_DISPLAY[status].sxColor}
              key={status}
            >
              {giftTickets.filter(req => req.status === status).length}
              {i !== REQUEST_STATUSES.length - 1 && ' - '}
            </Typography>
          ))}
          )
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1" align="center">
          Biglietti:
        </Typography>
        <Box>
          {totalRequestedGiftTickets} (
          {REQUEST_STATUSES.map((status, i) => (
            <Typography
              variant="body1"
              display="inline"
              color={REQ_STATUS_DISPLAY[status].sxColor}
              key={status}
            >
              {computeTotalGIftTickets(
                giftTickets.filter(req => req.status === status)
              )}
              {i !== REQUEST_STATUSES.length - 1 && ' - '}
            </Typography>
          ))}
          )
        </Box>
      </Box>
      <AccordionList
        sx={{ mt: 2 }}
        items={REQ_TYPES.map((type, typeIdx) => {
          const requestOfType = giftTickets.filter(req => req.type === type);
          return {
            title: GiftTypesHumanReadable[type],
            disabled: requestOfType.length === 0,
            badgeColor: requestOfType.some(
              req => req.status === ERequestStatus.pending
            )
              ? REQ_STATUS_DISPLAY[ERequestStatus.pending].sxColor
              : undefined,
            content: (
              <AccordionList
                items={Object.values(ERequestStatus).map(status => {
                  const reqsPerStatus = giftTickets.filter(
                    req => req.type === type && req.status === status
                  );
                  return {
                    title: REQ_STATUS_DISPLAY[status].text,
                    sxColor: REQ_STATUS_DISPLAY[status].sxColor,
                    subTitle: `${reqsPerStatus.length}`,
                    disabled: reqsPerStatus.length === 0,
                    content: (
                      <Stack gap={2}>
                        {reqsPerStatus.map((req, i) => (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            key={i}
                            gap={1}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                textDecoration:
                                  req.status === 'rejected'
                                    ? 'line-through'
                                    : 'none',
                              }}
                            >
                              {req.name}{' '}
                              {req.ticketsCount && `- ${req.ticketsCount} `}
                              {type === 'giveAway' && (
                                <>
                                  <br />
                                  <i>
                                    @{req.igHandle} - {req.mobile}
                                  </i>
                                </>
                              )}
                              <br />
                              <i>{req.email}</i>
                            </Typography>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              {req.status === 'pending' && (
                                <>
                                  <CustomButton
                                    useIconButton
                                    onClick={() => rejectRequest(req)}
                                  >
                                    <Clear />
                                  </CustomButton>
                                  <CustomButton
                                    useIconButton
                                    onClick={() => acceptRequest(req)}
                                  >
                                    <Check />
                                  </CustomButton>
                                </>
                              )}
                              {req.status === 'accepted' && (
                                <Check
                                  fontSize="large"
                                  sx={{
                                    color:
                                      REQ_STATUS_DISPLAY['accepted'].sxColor,
                                  }}
                                />
                              )}
                            </Box>
                          </Box>
                        ))}
                      </Stack>
                    ),
                  };
                })}
              />
            ),
          };
        })}
      />
    </Box>
  );
}

export default GiftTicketsManager;
