import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fragment, useState } from 'react';
import getUnixTime from 'date-fns/getUnixTime';
import {
  EScheduleLimits,
  ESessionActivationType,
  ESessionOpenTarget,
  ISchedule,
  TOpenTargetActivateInfo,
} from '../../interfaces';
import TouchApp from '@mui/icons-material/TouchApp';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import Switch from '@mui/material/Switch';
import compareAsc from 'date-fns/compareAsc';
import { OPEN_TARGET } from '../../constants';
import SchedulePicker from './SchedulePicker';

const scheduletoUnix = (sched: ISchedule<Date | null>): ISchedule => {
  return Object.keys(sched).reduce(
    (acc, limit) => ({
      ...acc,
      [limit]: getUnixTime(sched[limit as EScheduleLimits]!),
    }),
    {} as ISchedule
  );
};

const isDateValid = (d: Date | null) =>
  d instanceof Date && !isNaN(d.getTime());

export const isScheduleValid = (schedule: ISchedule<Date | null>) =>
  compareAsc(schedule['start']!, schedule['end']!) === 1 ||
  compareAsc(schedule['start']!, schedule['end']!) === 0;

interface IActivationTypeSelectorProps {
  onConfirm: (targetActivateInfos: TOpenTargetActivateInfo) => void;
}

function ActivationTypeSelector(props: IActivationTypeSelectorProps) {
  const { onConfirm } = props;

  const [schedules, setSchedules] = useState(
    Object.values(ESessionOpenTarget).reduce(
      (acc, openTarget) => ({
        ...acc,
        [openTarget]: { start: new Date(), end: new Date() },
      }),
      {} as { [key in ESessionOpenTarget]: ISchedule<Date | null> }
    )
  );
  const [isOnSchedule, setIsOnSchedule] = useState(
    Object.values(ESessionOpenTarget).reduce(
      (acc, openTarget) => ({ ...acc, [openTarget]: false }),
      {} as { [key in ESessionOpenTarget]: boolean }
    )
  );

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <Typography variant="h5" my={1}>
        Seleziona la programmazione per la sessione
      </Typography>
      {Object.values(ESessionOpenTarget).map((openTarget, i) => (
        <Fragment key={openTarget}>
          <Typography variant="h5" mt={3} color="primary">
            {OPEN_TARGET[openTarget]}
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography align="right">Ad attivazione</Typography>
            <TouchApp />
            <Switch
              checked={isOnSchedule[openTarget]}
              onChange={() => {
                setIsOnSchedule({
                  ...isOnSchedule,
                  [openTarget]: !isOnSchedule[openTarget],
                });
              }}
            />
            <QueryBuilder />
            <Typography>Programmata</Typography>
          </Box>
          {isOnSchedule[openTarget] && (
            <SchedulePicker
              schedule={schedules[openTarget]}
              onScheduleChange={newSchedule => {
                setSchedules({ ...schedules, [openTarget]: newSchedule });
              }}
            />
          )}
        </Fragment>
      ))}
      <Button
        variant="contained"
        onClick={() => {
          const newSchedule = Object.values(ESessionOpenTarget).reduce(
            (acc, openTarget) => ({
              ...acc,
              [openTarget]: isOnSchedule[openTarget]
                ? {
                    type: ESessionActivationType.onSchedule,
                    open: scheduletoUnix(schedules[openTarget]),
                  }
                : { type: ESessionActivationType.onPrompt, open: false },
            }),
            {} as TOpenTargetActivateInfo
          );
          onConfirm(newSchedule);
        }}
        disabled={Object.keys(schedules).some(ot => {
          const openTarget = ot as ESessionOpenTarget;
          if (!isOnSchedule[openTarget]) return false;
          const schedule = schedules[openTarget as ESessionOpenTarget];
          return (
            Object.values(schedule).some(date => date && !isDateValid(date)) ||
            isScheduleValid(schedule)
          );
        })}
      >
        Prosegui
      </Button>
    </Box>
  );
}

export default ActivationTypeSelector;
