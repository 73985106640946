import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { ReactNode } from 'react';
import { Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';
import { timestampStr, triggerTextDownload } from '../utils';
import Papa from 'papaparse';

interface IVoteTableProps {
  columnTitles: string[];
  rows: (string | number | ReactNode)[][];
  downloadFileName?: string;
}

function TextTable(props: IVoteTableProps) {
  const { rows, columnTitles, downloadFileName } = props;

  const downloadData = () => {
    const csv = Papa.unparse([columnTitles, ...rows]);
    triggerTextDownload(
      csv,
      `${downloadFileName ?? `GC_Console`}_${timestampStr()}.csv`
    );
  };

  return (
    <Box position="relative">
      <IconButton
        size="small"
        sx={{
          position: 'absolute',
          top: 8,
          right: 1,
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
        }}
        onClick={downloadData}
      >
        <DownloadIcon />
      </IconButton>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columnTitles.map(title => (
                <TableCell key={title}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
                key={i}
              >
                {row.map((val, valIdx) => (
                  <TableCell key={valIdx}>{val}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TextTable;
