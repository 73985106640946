import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomButton from '../misc/CustomButton';
import { useEffect, useState } from 'react';
import { db } from '../../fbConfig';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { PUBLIC_INFO_DOC_ID, MNG_INFO_COLL_ID } from '../../constants';
import { IPublicInfo } from '../../interfaces';
import { Switch } from '@mui/material';
import { isUrlWrong } from '../../utils';

const updatePublicInfo = (
  currentPublicInfo: IPublicInfo,
  newShowStreamingBtn: boolean,
  newStreamingUrl: string,
  newIgUrl: string
) => {
  const newPublicInfo: IPublicInfo = {
    ...currentPublicInfo,
    streamingUrl: newStreamingUrl,
    igUrl: newIgUrl,
    showStreamingBtn: newShowStreamingBtn,
  };
  return setDoc(doc(db, MNG_INFO_COLL_ID, PUBLIC_INFO_DOC_ID), newPublicInfo, {
    merge: true,
  })
    .then()
    .catch(err => {
      console.error('ERROR WHEN UPDATING STRAMING URL', err);
    });
};

function GeneralMngForm() {
  const [publicInfo, setPublicInfo] = useState<IPublicInfo | undefined>(
    undefined
  );

  const [showStreamingBtn, setShowStreamingBtn] = useState(false);
  const [newStreamingUrl, setNewStreamingUrl] = useState('');
  const [newIgUrl, setNewIgUrl] = useState('');
  const [openPublicInfo, setOpenPublicInfo] = useState(false);

  useEffect(() => {
    if (openPublicInfo) {
      const clearOnSnapShot = onSnapshot(
        doc(db, MNG_INFO_COLL_ID, PUBLIC_INFO_DOC_ID),
        snap => {
          setPublicInfo(snap.data() as IPublicInfo);
        },
        err => {
          console.error('ERROR WHEN LISTENING TO GENENRAL INFO DOC', err);
        }
      );
      return () => {
        clearOnSnapShot();
      };
    }
  }, [openPublicInfo]);

  useEffect(() => {
    if (publicInfo) {
      setNewStreamingUrl(publicInfo?.streamingUrl ?? '');
      setNewIgUrl(publicInfo?.igUrl ?? '');
      setShowStreamingBtn(publicInfo?.showStreamingBtn ?? false);
    }
  }, [publicInfo]);

  const isStreamingUrlWrong = isUrlWrong(newStreamingUrl);
  const isIgUrlWrong = isUrlWrong(newIgUrl);

  const toggleOpenPublicInfo = () => {
    setOpenPublicInfo(!openPublicInfo);
  };

  return (
    <>
      <Dialog open={openPublicInfo} onClose={toggleOpenPublicInfo}>
        <Box
          gap={3}
          m={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography color="primary" variant="h5">
            Modifica informazioni pubbliche
          </Typography>

          <FormControlLabel
            control={
              <Switch
                checked={showStreamingBtn}
                onChange={ev => {
                  setShowStreamingBtn(ev.target.checked);
                }}
              />
            }
            label="Mostra tasto per vedere la diretta"
          />

          <TextField
            value={newStreamingUrl}
            onChange={e => {
              setNewStreamingUrl(e.target.value);
            }}
            fullWidth
            required
            id="streaming-url"
            label="URL della diretta streaming"
            error={isStreamingUrlWrong}
            helperText={isStreamingUrlWrong && 'Url non valido'}
          />
          <TextField
            value={newIgUrl}
            onChange={e => {
              setNewIgUrl(e.target.value);
            }}
            fullWidth
            required
            id="instagram-url"
            label="URL della pagina instagram"
            error={isIgUrlWrong}
            helperText={isIgUrlWrong && 'Url non valido'}
          />
          <CustomButton
            variant="contained"
            onClick={() => {
              updatePublicInfo(
                publicInfo!,
                showStreamingBtn,
                newStreamingUrl,
                newIgUrl
              ).then(() => {
                toggleOpenPublicInfo();
              });
            }}
            disabled={
              newStreamingUrl === '' ||
              isStreamingUrlWrong ||
              newIgUrl === '' ||
              isIgUrlWrong
            }
          >
            Aggiorna pagina votazioni
          </CustomButton>
        </Box>
      </Dialog>
      <Stack gap={2}>
        <CustomButton variant="outlined" onClick={toggleOpenPublicInfo}>
          Modifica informazioni pubbliche
        </CustomButton>
      </Stack>
    </>
  );
}

export default GeneralMngForm;
