// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import temp from './fbConfig.json';
const firebaseConfig = temp.result.sdkConfig;
const fbApp = initializeApp(firebaseConfig);
console.info('Target project', firebaseConfig.projectId);

export const auth = getAuth();
export const functions = getFunctions();
export const db = initializeFirestore(fbApp, {
  ignoreUndefinedProperties: true,
});
export const stor = getStorage();

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  // db.settings({ host: 'localhost:8080', ssl: false });
  // auth.useEmulator('http://localhost:9099/');
}
functions.region = 'europe-west3';
auth.useDeviceLanguage();

console.info('KAWABANGA by GabriFila');
