import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { EScheduleLimits, ISchedule } from '../../interfaces';
import { DATE_PICKER_LABEL } from '../../constants';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import itLocale from 'date-fns/locale/it';

interface ISchedulePickerProps {
  schedule: ISchedule<Date | null>;
  onScheduleChange: (newSchedule: ISchedule<Date | null>) => void;
}

function SchedulePicker(props: ISchedulePickerProps) {
  const { schedule, onScheduleChange } = props;
  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
        {Object.values(EScheduleLimits).map((limit, j) => (
          <Box
            key={limit}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            maxWidth="330px"
            gap={2}
          >
            <Typography>{DATE_PICKER_LABEL[limit]}:</Typography>
            <DateTimePicker
              renderInput={props => <TextField {...props} />}
              value={schedule[limit]}
              onChange={newValue => {
                const newDateLimits = { ...schedule };
                newDateLimits[limit] = newValue;
                newDateLimits[limit]?.setSeconds(0);
                onScheduleChange(newDateLimits);
              }}
              minDate={
                limit === EScheduleLimits.end ? schedule['start'] : new Date()
              }
              ampm={false}
            />
          </Box>
        ))}
      </LocalizationProvider>
    </Box>
  );
}

export default SchedulePicker;
