import Box from '@mui/system/Box';
import { useEffect, useState, SyntheticEvent, Fragment } from 'react';
import Typography from '@mui/material/Typography';
import { ITalent, IJudgeDoc } from '../../interfaces';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import CreateTalentForm from './CreateTalentForm';
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  runTransaction,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../../fbConfig';
import PickerElement from '../PickerElement';
import GroupSelector from '../GroupSelector';
import CreateJudgeForm from './CreateJudgeForm';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {
  MNG_INFO_COLL_ID,
  JUDGES_LIST_DOC_ID,
  TALENTS_COLL_ID,
} from '../../constants';
import FileUploader from '../misc/FileUploader';
import Papa from 'papaparse';
import TalentsTable from '../TalentsTable';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';

interface ITalentSessionPickerProps {
  groupNames: string[];
  includeJudges: boolean;
  songsPerTalent: number;
  onConfirm: (selectedTalents: ITalent[][], selectedJudges: string[]) => void;
}

const deleteTalent = (talId: string) => {
  deleteDoc(doc(db, TALENTS_COLL_ID, talId))
    .then(res => {
      console.info('Talent deleted successfully');
    })
    .catch(err => {
      console.error('ERROR WHEN DELETING TALENT', err);
    });
};

const deleteJudge = (name: string) => {
  const judgeListRef = doc(db, MNG_INFO_COLL_ID, JUDGES_LIST_DOC_ID);
  runTransaction(db, t =>
    t.get(judgeListRef).then(snap => {
      const judgeListDoc = snap.data() as IJudgeDoc;
      judgeListDoc.judges = judgeListDoc.judges.filter(judge => judge !== name);
      t.set(judgeListRef, judgeListDoc);
    })
  )
    .then(() => {
      console.info('Judge delete successfully!');
    })
    .catch(err => {
      console.error('ERROR WHEN DELETING JUDGE', err);
    });
};

const CSV_COLUMN_MAPPER: { [talProp in keyof ITalent]?: string } = {
  id: 'id',
  name: 'nome',
  schoolName: 'nome scuola',
  schoolType: 'tipo scuola',
  schoolDomain: 'dominio',
};

function SessionParticipantsPicker(props: ITalentSessionPickerProps) {
  const { onConfirm, groupNames, includeJudges, songsPerTalent } = props;
  const [availTalents, setAvailTalents] = useState<ITalent[]>([]);
  const [selectedTalPerGroup, setSelectedTalPerGroup] = useState<
    { [talId: string]: boolean }[]
  >(groupNames.map(_ => ({})));

  const [addFormOpen, setAddFormOpen] = useState(false);

  const someTalSelectedForEachGroup = selectedTalPerGroup.every(groupSels =>
    Object.values(groupSels).some(selTal => selTal)
  );

  const [selectedGroup, setSelectedGroup] = useState(0);
  const [pickerTarget, setPickerTarget] = useState<number>(0);
  const [judges, setJudges] = useState<string[]>([]);
  const [selectedJudges, setSelectedJudges] = useState<boolean[]>([]);

  const someJudgeSelected = selectedJudges.filter(sel => sel).length;

  const handleChange = (ev: SyntheticEvent, newValue: number) => {
    setPickerTarget(newValue);
  };

  useEffect(() => {
    const clearSnapshot = onSnapshot(
      collection(db, TALENTS_COLL_ID),
      snaps => {
        setSelectedTalPerGroup(prevSelected =>
          groupNames.map((_, groupIdx) => ({
            ...snaps.docs
              .map(doc => doc.id)
              .reduce((acc, val) => ({ ...acc, [val]: false }), {}),
            ...prevSelected[groupIdx],
          }))
        );
        setAvailTalents(
          snaps.docs.map(doc => ({ id: doc.id, ...doc.data() })) as ITalent[]
        );
      },
      err => {
        console.error('Error when listeing to talents updates', err);
      }
    );
    return () => {
      clearSnapshot();
    };
  }, [groupNames]);

  useEffect(() => {
    const clearSnapshot = onSnapshot(
      doc(db, MNG_INFO_COLL_ID, JUDGES_LIST_DOC_ID),
      snap => {
        const judgesDoc = snap.data() as IJudgeDoc;
        setSelectedJudges(judgesDoc?.judges.map(_ => false) ?? []);
        setJudges(judgesDoc?.judges ?? []);
      },
      err => {
        console.error('Error when listeing to judges updates', err);
      }
    );
    return () => {
      clearSnapshot();
    };
  }, []);
  const handleTalentFormClose = () => {
    setAddFormOpen(false);
  };

  const [csvUrl, setCsvUrl] = useState<string | undefined>(undefined);
  const [parsedCsvTalents, setParsedCsvTalents] = useState<
    ITalent[] | undefined
  >(undefined);

  const [csvTalentsOpen, setCsvTalentsOpen] = useState(false);
  const [csvInfoOpen, setCsvInfoOpen] = useState(false);

  useEffect(() => {
    if (csvUrl) {
      fetch(csvUrl)
        .then(res => res.text())
        .then(csvString => {
          const csvParsed = Papa.parse(csvString, {
            header: true,
            delimiter: ',',
          });

          const newTalents: ITalent[] = csvParsed.data
            .map((row: any) => {
              return {
                id: row[CSV_COLUMN_MAPPER.id as string],
                name: row[CSV_COLUMN_MAPPER.name as string],
                schoolName: row[CSV_COLUMN_MAPPER.schoolName as string],
                schoolType: row[CSV_COLUMN_MAPPER.schoolType as string],
                schoolDomain: row[CSV_COLUMN_MAPPER.schoolDomain as string],
                songs: [
                  {
                    isOriginal: false,
                    author: row['autore1'] as string,
                    name: row['canzone1'] as string,
                    id: '',
                    urls: [
                      row['youtube1'] as string,
                      row['spotify1'] as string,
                    ],
                  },
                  {
                    isOriginal: false,
                    author: row['autore2'] as string,
                    name: row['canzone2'] as string,
                    id: '',
                    urls: [
                      row['youtube2'] as string,
                      row['spotify2'] as string,
                    ],
                  },
                  {
                    isOriginal: false,
                    author: row['autore3'] as string,
                    name: row['canzone3'] as string,
                    id: '',
                    urls: [
                      row['youtube3'] as string,
                      row['spotify3'] as string,
                    ],
                  },
                ],
                imgUrl: '',
                videoUrl: '',
                igHandlers: [],
              };
            })
            .filter((obj: Object) =>
              Object.values(obj).every(
                val => val !== undefined && val !== 'undefined'
              )
            );

          newTalents.forEach(tal => {
            tal.schoolDomain =
              tal.schoolDomain === '' ? undefined : tal.schoolDomain;
            tal.songs?.forEach((song, songIdx) => {
              tal.songs![songIdx].isOriginal = tal.name === song.author;
              tal.songs![songIdx].id = `${tal.id}_${songIdx}`;
            });
          });
          setParsedCsvTalents(newTalents);
          setCsvTalentsOpen(true);
        });
    }
  }, [csvUrl]);

  // TODO handle id repetition in file
  const createTalents = () => {
    const batch = writeBatch(db);
    parsedCsvTalents?.forEach(tal => {
      batch.set(doc(db, TALENTS_COLL_ID, tal.id), tal);
    });

    batch.commit().then();
  };

  return (
    <>
      <Dialog open={addFormOpen} onClose={handleTalentFormClose}>
        {pickerTarget === 0 ? (
          <CreateTalentForm
            onCreationSuccess={handleTalentFormClose}
            existingIds={availTalents.map(tal => tal.id)}
            songsPerTalent={songsPerTalent}
          />
        ) : (
          <CreateJudgeForm
            onClose={handleTalentFormClose}
            existingNames={judges}
          />
        )}
      </Dialog>
      {parsedCsvTalents && (
        <Dialog open={csvTalentsOpen} onClose={() => setCsvTalentsOpen(false)}>
          <Box
            p={3}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <Typography>
              Totale talenti da aggiungere: {parsedCsvTalents.length}
            </Typography>
            {parsedCsvTalents.length === 0 ? (
              <Typography align="center" color="error">
                Non è possible recuperare dati dal file che hai caricato
              </Typography>
            ) : (
              <>
                <Typography align="center" color="warning">
                  Controlla i dati, se ci sono degli errori correggili e
                  ricarica il file
                </Typography>
                <TalentsTable talents={parsedCsvTalents ?? []} />

                <Button
                  sx={{}}
                  variant="contained"
                  onClick={() => {
                    createTalents();
                  }}
                >
                  Carica talenti
                </Button>
              </>
            )}
          </Box>
        </Dialog>
      )}
      <Fab
        color="primary"
        variant="extended"
        sx={{ position: 'fixed', right: 30, bottom: 30, zIndex: 3 }}
        onClick={() => {
          setAddFormOpen(true);
        }}
      >
        {pickerTarget === 0 ? 'Nuovo talento' : 'Nuovo giudice'}
        <AddIcon sx={{ ml: 1 }} />
      </Fab>
      <Container maxWidth={false}>
        <Box my={5}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <FileUploader
              setDataUrl={setCsvUrl}
              accept=".csv"
              id="csv-talents"
              icon={<AddIcon />}
              text="Carica csv talenti"
            />
            <IconButton
              onClick={() => {
                setCsvInfoOpen(true);
              }}
            >
              <InfoOutlinedIcon color="primary" />
            </IconButton>
            <Dialog open={csvInfoOpen} onClose={() => setCsvInfoOpen(false)}>
              <Box
                p={3}
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
              >
                <Typography>
                  Per caricare i talenti, devi usare un file .csv, le colonne
                  deveono avere questi nomi:
                  <br />
                  <br />
                  {Object.values(CSV_COLUMN_MAPPER).map(colName => (
                    <Fragment key={colName}>
                      &nbsp; &nbsp; - {colName}
                      <br />
                    </Fragment>
                  ))}
                  <br />
                  <i>Rispetta le maiuscole e le minuscole</i>
                </Typography>
              </Box>
            </Dialog>
          </Box>
          <Button
            sx={{ position: 'absolute', right: 10, top: 10 }}
            variant="contained"
            disabled={
              !someTalSelectedForEachGroup ||
              (includeJudges && !someJudgeSelected)
            }
            onClick={() => {
              onConfirm(
                groupNames.map((_, groupIdx) =>
                  availTalents.filter(
                    ({ id: talId }) => selectedTalPerGroup[groupIdx][talId]
                  )
                ),
                judges.filter((_, judIdx) => selectedJudges[judIdx])
              );
            }}
          >
            Prosegui
          </Button>
          {includeJudges && (
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-around',
                width: '100%',
              }}
            >
              <Tabs value={pickerTarget} onChange={handleChange}>
                <Tab
                  label={`Talenti (${
                    selectedTalPerGroup
                      .flatMap(selTals => Object.values(selTals))
                      .filter(selTal => selTal).length
                  })`}
                />
                <Tab
                  label={`Giudici (${
                    selectedJudges.filter(seljud => seljud).length
                  })`}
                />
              </Tabs>
            </Box>
          )}
          <Typography align="center" my={2}>
            Seleziona i talenti
          </Typography>
          {pickerTarget === 0 && groupNames.length !== 1 && (
            <GroupSelector
              groupNames={groupNames}
              onChange={newGroupIdx => {
                setSelectedGroup(newGroupIdx);
              }}
              talSelPerGroup={selectedTalPerGroup.map(
                groupSels =>
                  Object.values(groupSels).filter(talSel => talSel).length
              )}
            />
          )}
          <Grid
            container
            columnSpacing={2}
            rowSpacing={4}
            justifyContent="space-around"
            alignItems="stretch"
            justifyItems="stretch"
            mt={1}
          >
            {pickerTarget === 0
              ? availTalents.map(
                  ({ id, name, schoolName, schoolType, imgUrl }) => {
                    const disabled = selectedTalPerGroup
                      .filter((_, groupIdx) => groupIdx !== selectedGroup)
                      .some(selTals => selTals[id]);
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={2}
                        xl={1}
                        key={id}
                        onClick={() => {
                          const newSelTalIds = [...selectedTalPerGroup];
                          newSelTalIds[selectedGroup][id] =
                            !selectedTalPerGroup[selectedGroup][id];
                          setSelectedTalPerGroup(newSelTalIds);
                        }}
                        sx={{
                          pointerEvents: disabled ? 'none' : 'auto',
                        }}
                      >
                        <PickerElement
                          selected={selectedTalPerGroup[selectedGroup][id]}
                          imgUrl={imgUrl}
                          title={name}
                          subTitle={`${schoolType} ${schoolName}`}
                          subSubTitle={id}
                          disabled={disabled}
                          secondaryActionType={'delete'}
                          secondaryAction={() => {
                            deleteTalent(id);
                          }}
                        />
                      </Grid>
                    );
                  }
                )
              : judges.map((judge, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={2}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    key={judge}
                    onClick={() => {
                      const newSelJudIdx = [...selectedJudges];
                      newSelJudIdx[i] = !selectedJudges[i];
                      setSelectedJudges(newSelJudIdx);
                    }}
                  >
                    <PickerElement
                      selected={selectedJudges[i]}
                      title={judge}
                      subTitle=""
                      subSubTitle={''}
                      disabled={false}
                      secondaryActionType={'delete'}
                      secondaryAction={() => {
                        deleteJudge(judge);
                      }}
                    />
                  </Grid>
                ))}
          </Grid>
        </Box>
      </Container>
      <Typography align="center" mt={2}>
        Se non trovi il talento, creane uno nuovo
      </Typography>
    </>
  );
}

export default SessionParticipantsPicker;
