import { useCallback, useState } from "react";

export default function useTimeTrigger(debug = false) {
  const [timeTrigger, setTimeTrigger] = useState(Date.now() / 1000);

  const triggerTimeAt = useCallback(
    (newTimeTrigger: number) => {
      if (debug)
        console.debug(
          `SCHEDULING NEW TRIGGER UPDATE IN ${
            (newTimeTrigger * 1000 - Date.now()) / 1000
          }s`
        );
      const timeout = setTimeout(() => {
        console.debug("NEW TRIGGER");
        setTimeTrigger(newTimeTrigger);
      }, newTimeTrigger * 1000 - Date.now());
      return () => {
        console.debug("CLEARING TIME TRIGGER");
        clearTimeout(timeout);
      };
    },
    [debug]
  );

  return [timeTrigger, triggerTimeAt] as [
    number,
    (newTimeTrigger: number) => () => void
  ];
}
