import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  GroupTalentOrder,
  IGroup,
  IJudge,
  ILiveRankCred,
  VotingScheme,
} from '../interfaces';
import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../fbConfig';
import { NonAudienceVoteType, TNonAudPswDoc } from '../interfaces';
import {
  ADD_INFO_COLLECTION_ID,
  LIVE_RANK_CRED_DOC_ID,
  NON_AUDIENCE_USER_DATA_DOC_ID,
  SESSIONS_COLL_ID,
} from '../constants';
import TextTable from './TextTable';
import AccordionList from './Accordion';
import TalentsTable from './TalentsTable';

interface ITalentRecapTableProps {
  groups: IGroup[];
  judges: IJudge[];
  description: string;
  id: string;
  showCreds: boolean;
  votingScheme: VotingScheme;
  songsPerTalent: number | undefined;
  groupTalentOrder: GroupTalentOrder;
}

const NON_AUD_TRANSL: { [key in NonAudienceVoteType]: string } = {
  jury: 'giuria',
  rappr: 'rappresentanti',
};

function SessionRecap(props: ITalentRecapTableProps) {
  const {
    groups,
    description,
    id,
    judges,
    showCreds,
    votingScheme,
    songsPerTalent,
    groupTalentOrder,
  } = props;
  const [psws, setPsws] = useState<TNonAudPswDoc>({});
  const [liveRankCred, setLiveRankCred] = useState<undefined | ILiveRankCred>(
    undefined
  );

  useEffect(() => {
    if (showCreds) {
      const clearOnSnapshot = onSnapshot(
        doc(
          db,
          SESSIONS_COLL_ID,
          id,
          ADD_INFO_COLLECTION_ID,
          NON_AUDIENCE_USER_DATA_DOC_ID
        ),
        snap => {
          setPsws(snap.data() as TNonAudPswDoc);
        },
        err => {
          console.error('ERROR WHEN LISTENING TO NON AUD PASSWORDS', err);
        }
      );
      return () => {
        clearOnSnapshot();
      };
    }
  }, [showCreds, id]);

  useEffect(() => {
    if (showCreds) {
      const clearOnSnapshot = onSnapshot(
        doc(
          db,
          SESSIONS_COLL_ID,
          id,
          ADD_INFO_COLLECTION_ID,
          LIVE_RANK_CRED_DOC_ID
        ),
        snap => {
          setLiveRankCred(snap.data() as ILiveRankCred);
        },
        err => {
          console.error('ERROR WHEN LISTENING TO LIVE RANK', err);
        }
      );
      return () => {
        clearOnSnapshot();
      };
    }
  }, [showCreds, id]);

  return (
    <>
      <Box>
        <Typography color="primary" display="inline">
          <b>Id: </b>
        </Typography>
        <Typography display="inline"> {id}</Typography>
      </Box>
      <Box>
        <Typography color="primary" display="inline">
          <b>Descrizione: </b>
        </Typography>
        <Typography display="inline"> {description}</Typography>
      </Box>
      <Box>
        <Typography color="primary" display="inline">
          <b>Gruppi totali: </b>
        </Typography>
        <Typography display="inline"> {groups.length}</Typography>
      </Box>
      <Box>
        <Typography color="primary" display="inline">
          <b>Talenti totali: </b>
        </Typography>
        <Typography display="inline">
          {' '}
          {groups.flatMap(group => group.talents).length}
        </Typography>
      </Box>
      <Box>
        <Typography color="primary" display="inline">
          <b>Schema votazione: </b>
        </Typography>
        <Typography display="inline">
          {votingScheme === 'basic' ? 'Base' : 'Con scelta canzoni'}
        </Typography>
      </Box>
      {votingScheme === VotingScheme.selectSongs && (
        <Box>
          <Typography color="primary" display="inline">
            <b>Canzoni per talento: </b>
          </Typography>
          <Typography display="inline">{songsPerTalent!}</Typography>
        </Box>
      )}

      <AccordionList
        sx={{ mt: 3 }}
        items={[
          {
            title: 'Componenti giuria',
            content: (
              <TextTable
                columnTitles={['Nome', 'Peso']}
                rows={judges.map(({ name, percentage }) => [name, percentage])}
              />
            ),
            disabled: judges.length === 0,
          },
          {
            title: 'Elenco talenti',
            content: groups.map(({ name, talents }) => (
              <Box my={3} key={name}>
                <Typography align="center">
                  <i>Girone {name}</i>
                </Typography>
                <TalentsTable
                  talents={talents.sort(
                    (a, b) =>
                      groupTalentOrder[name][a.id] -
                      groupTalentOrder[name][b.id]
                  )}
                />
              </Box>
            )),
          },
          // TODO think if is reaslly necessary to hide credentials (they should actually always be visible since the user just created them)
          ...(showCreds
            ? Object.values(NonAudienceVoteType).map(nonAudType => ({
                title: `Credenziali ${NON_AUD_TRANSL[nonAudType]}`,
                content: (
                  <TextTable
                    columnTitles={['Username', 'Password']}
                    rows={Object.keys(psws)
                      .filter(username => psws[username].type === nonAudType)
                      .map(username => [username, psws[username].psw])
                      .sort((a, b) => a[0].localeCompare(b[0]))}
                  />
                ),
              }))
            : []),
          ...(showCreds && liveRankCred
            ? [
                {
                  title: 'Credenziali classifica eurovision',
                  content: (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      maxWidth="150px"
                      margin="auto"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography>
                          <b>User: </b>
                        </Typography>
                        <Typography>{liveRankCred.username}</Typography>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography>
                          <b>Password: </b>
                        </Typography>
                        <Typography>{liveRankCred.psw}</Typography>
                      </Box>
                    </Box>
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  );
}

export default SessionRecap;
