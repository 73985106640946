import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FileUploader from '../misc/FileUploader';
import { doc, setDoc } from 'firebase/firestore';
import { db, stor } from '../../fbConfig';
import { TALENTS_COLL_ID } from '../../constants';
import { ISong, ITalentBasic } from '../../interfaces';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import AddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';
import { arrOfLen } from '../../utils';
import SongField from './SongField';

const SCHOOL_TYPES = ['Liceo', 'Istituto'];

interface ICreateTalentFormProps {
  onCreationSuccess: () => void;
  existingIds: string[];
  songsPerTalent: number;
}

function CreateTalentForm(props: ICreateTalentFormProps) {
  const { onCreationSuccess, existingIds, songsPerTalent } = props;
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [schoolDomain, setSchoolDomain] = useState<string | undefined>(
    undefined
  );
  const [picUrl, setPic] = useState<string | undefined>(undefined);
  const [songs, setSongs] = useState<ISong[]>(
    arrOfLen(songsPerTalent).map(() => ({
      author: '',
      name: '',
      id: '',
      isOriginal: false,
      urls: [''],
    }))
  );

  useEffect(() => {
    setSongs(prevSongs => {
      return prevSongs.map((song, songIdx) => ({
        ...song,
        id: `${id}_${songIdx}`,
      }));
    });
  }, [id]);

  const createTalent = () => {
    const imgRef = ref(stor, `talents/${id}`);
    Promise.all(picUrl ? [uploadString(imgRef, picUrl, 'data_url')] : [])
      .then(() => {
        console.info('Talent image uploaded successfully!');
        if (picUrl) return getDownloadURL(imgRef);
        return;
      })
      .then(url => {
        const newTalent: ITalentBasic = {
          name,
          schoolName,
          schoolType,
          schoolDomain,
          igHandlers: [],
          imgUrl: url,
          songs,
        };
        const createTalDoc = setDoc(doc(db, TALENTS_COLL_ID, id), newTalent);
        return createTalDoc;
      })
      .then(() => {
        console.info('Talent created succesfully successfully!');
        onCreationSuccess();
      })
      .catch(err => {
        console.error('ERROR WHEN CREATING NEW TALENT', err);
      });
  };

  const idAlreadyExists = existingIds.includes(id);

  const isDomainWrong = schoolDomain
    ? schoolDomain !== '' &&
      (!schoolDomain.includes('.') || schoolDomain.at(-1) === '.')
    : false;

  return (
    <Box
      margin={2}
      display="flex"
      flexDirection="column"
      gap={2}
      alignItems="center"
    >
      <Typography color="primary">Crea un nuovo talento</Typography>
      <TextField
        value={id}
        onChange={e => {
          setId(e.target.value);
        }}
        required
        label="Id"
        id="id-talento"
        error={idAlreadyExists}
        helperText={idAlreadyExists && 'Id già in uso'}
      />
      <TextField
        value={name}
        onChange={e => {
          setName(e.target.value);
        }}
        required
        label="Nome completo"
        id="nome-talento"
      />
      <TextField
        value={schoolType}
        onChange={e => {
          setSchoolType(e.target.value);
        }}
        required
        select
        label="Tipo scuola"
        id="tipo-scuola"
        fullWidth
      >
        {SCHOOL_TYPES.map(st => (
          <MenuItem key={st} value={st}>
            {st}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        value={schoolName}
        onChange={e => {
          setSchoolName(e.target.value);
        }}
        required
        label="Nome scuola"
        id="nome-scuola"
      />
      <TextField
        value={schoolDomain}
        onChange={e => {
          setSchoolDomain(e.target.value);
        }}
        label="Dominio email scuola"
        id="dominio-scuola"
        error={isDomainWrong}
        helperText={
          isDomainWrong &&
          'Il dominio deve contenere almeno un punto e non finire con un punto'
        }
      />
      <FileUploader
        setDataUrl={setPic}
        accept="image/*"
        id="talent-pic"
        icon={<AddPhotoAlternate fontSize="large" />}
      />
      {picUrl && (
        <img
          src={picUrl}
          style={{ width: '100%', maxWidth: 200 }}
          alt={`preview-${name}`}
        />
      )}
      {songsPerTalent > 0 && (
        <>
          {songs.map((song, songIdx) => (
            <SongField
              song={song}
              onChange={newSong => {
                const newSongs = [...songs];
                newSongs[songIdx] = newSong;
                setSongs(newSongs);
              }}
            />
          ))}
        </>
      )}
      <Button
        variant="contained"
        disabled={
          !id ||
          !name ||
          !schoolType ||
          !schoolName ||
          isDomainWrong ||
          idAlreadyExists
        }
        onClick={createTalent}
      >
        Crea nuovo talento
      </Button>
    </Box>
  );
}

export default CreateTalentForm;
