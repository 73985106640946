import { ReactNode } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { CircularProgress, SxProps, Theme } from '@mui/material';

interface IAccordionListProps {
  items: {
    title: string;
    sxColor?: string;
    content: ReactNode;
    disabled?: boolean;
    subTitle?: string;
    loading?: boolean;
    badgeColor?: string;
  }[];
  sx?: SxProps<Theme>;
}

export default function AccordionList(props: IAccordionListProps) {
  const { items, sx } = props;
  return (
    <Stack gap={1} sx={{ maxWidth: '100%', ...sx }} id="ciao">
      {items.map(
        (
          { title, content, disabled, sxColor, subTitle, loading, badgeColor },
          i
        ) => (
          <Accordion disabled={disabled} key={i}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                sx={{ color: disabled ? '' : sxColor ?? 'primary.main' }}
              >
                {loading && <CircularProgress size="1em" sx={{ mr: 1 }} />}
                <Typography>{title}</Typography>
                {subTitle && <Typography>{subTitle}</Typography>}
                {badgeColor && (
                  <Box
                    width="15px"
                    height="15px"
                    borderRadius="100%"
                    bgcolor={badgeColor}
                  />
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails>{content}</AccordionDetails>
          </Accordion>
        )
      )}
    </Stack>
  );
}
