import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IJudge } from '../../interfaces';
import { useState } from 'react';

interface IJudgePercentageSelectProps {
  judges: IJudge[];
  onConfirm: (judges: IJudge[]) => void;
  onBack: () => void;
}
function JudgePercentageSelect(props: IJudgePercentageSelectProps) {
  const { judges, onConfirm } = props;
  const [percentages, setPercentages] = useState(judges.map(_ => '1.0'));

  const isTotal1 =
    percentages.reduce((tot, val) => {
      return Math.round((tot + Number(val)) * 100000) / 100000;
    }, 0) === 1;

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      <Typography my={2}>
        Inserisci un moltiplicatore per ogni giudice
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxWidth={250}
        width="100%"
        mb={2}
      >
        {judges.map(({ name }, i) => (
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            justifyContent="space-between"
            width="100%"
            key={i}
          >
            <Typography>{name}</Typography>
            <TextField
              type="tel"
              size="small"
              required
              sx={{ width: '75px' }}
              value={percentages[i]}
              onChange={e => {
                const newMuls = [...percentages];
                newMuls[i] = e.target.value;
                setPercentages(newMuls);
              }}
            />
          </Box>
        ))}
      </Box>
      {!isTotal1 && (
        <Typography color="error">Il totale deve esssere 1</Typography>
      )}
      <Button
        variant="contained"
        onClick={() => {
          onConfirm(
            judges.map(({ name }, i) => ({
              name,
              percentage: Number(percentages[i]),
            }))
          );
        }}
        disabled={percentages.some(mul => !mul) && !isTotal1}
      >
        Prosegui
      </Button>
    </Box>
  );
}

export default JudgePercentageSelect;
