import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ILiveRankCred } from '../../interfaces';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';
import { Switch } from '@mui/material';

interface ISessionInfoFormProps {
  onConfirm: (
    id: string,
    description: string,
    groupAmount: number,
    includeJudges: boolean,
    includeRappr: boolean,
    liveRankCred: ILiveRankCred
  ) => void;
  existingIds: string[];
}

function SessionInfoForm(props: ISessionInfoFormProps) {
  const { onConfirm, existingIds } = props;
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const [groupAmount, setGroupAmount] = useState('');
  const [usernameLiveRank, setUsernameLiveRank] = useState('');
  const [pswLiveRank, setPswLiveRank] = useState('');
  const [includeJudges, setIncludeJudges] = useState(false);
  const [includeRappr, setIncludeRappr] = useState(false);
  const idAlreasyInUse = existingIds.includes(id);

  const [showPsw, setShowPsw] = useState(false);

  const isGroupAmountWrong = Number(groupAmount) <= 0;

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const isPswNotOk = pswLiveRank.length < 10;

  return (
    <>
      <Typography color="primary" variant="h5">
        Crea una nuova votazione
      </Typography>
      <Stack gap={3}>
        <TextField
          value={id}
          onChange={e => {
            setId(e.target.value);
          }}
          required
          id="id-sessione"
          label="Id"
          error={idAlreasyInUse}
          helperText={idAlreasyInUse && "L'id è già in uso"}
        />
        <TextField
          value={description}
          onChange={e => {
            setDescription(e.target.value);
          }}
          required
          id="descrizione-sessione"
          label="Descrizione"
        />
        <TextField
          value={groupAmount}
          onChange={e => {
            setGroupAmount(e.target.value);
          }}
          required
          error={groupAmount !== '' && isGroupAmountWrong}
          helperText={
            groupAmount !== '' &&
            isGroupAmountWrong &&
            'Il numero dei gironi deve essere maggiore di 0'
          }
          fullWidth
          id="numero-gironi"
          type="number"
          label="Numero Gironi"
        />
        <Stack alignItems="center">
          <Typography>Includere i giudici nella votazione?</Typography>
          <Switch
            value={includeJudges}
            onChange={(_, newValue) => {
              setIncludeJudges(newValue);
            }}
          />
        </Stack>
        <Stack alignItems="center">
          <Typography>Includere i rappresentanti nella votazione? </Typography>
          <Switch
            value={includeRappr}
            onChange={(_, newValue) => {
              setIncludeRappr(newValue);
            }}
          />
        </Stack>
      </Stack>

      <Typography align="center" mt={1}>
        Credenziali per visualizzare classifica stile eurovision:
      </Typography>
      <Stack gap={3}>
        <TextField
          value={usernameLiveRank}
          onChange={e => {
            setUsernameLiveRank(e.target.value);
          }}
          required
          id="username-liveRank"
          label="Username"
          fullWidth
        />
        <TextField
          value={pswLiveRank}
          onChange={e => {
            setPswLiveRank(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPsw ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
          id="password-liveRank"
          error={pswLiveRank !== '' && isPswNotOk}
          type={showPsw ? 'text' : 'password'}
          label="Password"
          helperText="Almeno 10 caratteri"
        />
      </Stack>
      <Button
        variant="contained"
        disabled={
          !id ||
          !description ||
          isGroupAmountWrong ||
          idAlreasyInUse ||
          !usernameLiveRank ||
          !pswLiveRank ||
          isPswNotOk
        }
        onClick={() =>
          onConfirm(
            id,
            description,
            Number(groupAmount),
            includeJudges,
            includeRappr,
            {
              username: usernameLiveRank,
              psw: pswLiveRank,
            }
          )
        }
      >
        Prosegui
      </Button>
    </>
  );
}

export default SessionInfoForm;
