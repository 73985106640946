import Box from '@mui/system/Box';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  ESessionActivationType,
  GroupTalentOrder,
  ICreateSessionData,
  IGroup,
  IJudge,
  ITalent,
  TOpenTargetActivateInfo,
  VotingScheme,
} from '../../interfaces';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import SessionParticipantsPicker from './SessionParticipantPicker';
import ActivationTypeSelector from './ActivationTypeSelector';
import { arrOfLen } from '../../utils';
import CreateSessionRecap from './CreateSessionRecap';
import SessionInfoForm from './SessionInfoForm';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../fbConfig';
import useCallStatus from '../../hooks/useCallStatus';
import CallStatusDialog from '../misc/CallStatusDialog';
import JudgePercentageSelect from './JudgePercentageSelect';
import ArrowBack from '@mui/icons-material/ArrowBack';
import GroupNamer from './GroupNamer';
import VotingSchemeSelector from './VotingSchemeSelector';
import useStepper from '../../hooks/useStepper';
import TalentOrderSelector from './TalentOrderSelector';

interface ICreateSessionFormProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  existingIds: string[];
}

function CreateSessionForm(props: ICreateSessionFormProps) {
  const { open, setOpen, existingIds } = props;
  const [formStep, stepBack, stepForward, resetStep] = useStepper({});
  // TODO make custom hook to remember values using sessionStorage
  const [sessionInitData, setSessionInitData] = useState({
    id: '',
    description: '',
    groupAmount: 0,
    liveRankCred: { username: '', psw: '' },
    includeRappr: false,
    includeJudges: false,
  });

  const {
    id,
    description,
    groupAmount,
    liveRankCred,
    includeRappr,
    includeJudges,
  } = sessionInitData;
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [judges, setJudges] = useState<IJudge[]>([]);

  const [groupTalentOrder, setgroupTalentOrder] = useState<GroupTalentOrder>(
    {}
  );
  const [activateInfos, setActivateInfo] = useState<TOpenTargetActivateInfo>({
    openAud: { open: false, type: ESessionActivationType.onPrompt },
    openNonAud: { open: false, type: ESessionActivationType.onPrompt },
  });

  const [votingScheme, setVotingScheme] = useState<VotingScheme>(
    VotingScheme.basic
  );
  const [songsPerTalent, setSongsPerTalent] = useState<undefined | number>(
    undefined
  );

  const createSessionArg: ICreateSessionData = {
    session: {
      groups,
      id,
      description,
      judges,
      includeRappr,
      ...activateInfos,
      giftTicketsOpen: false,
      ticketsOpen: false,
      ...(votingScheme === VotingScheme.basic
        ? {
            votingScheme: VotingScheme.basic,
          }
        : {
            songsPerTalent: songsPerTalent ?? 0,
            votingScheme: VotingScheme.selectSongs,
          }),
      groupTalentOrder,
    },
    liveRankCred,
  };

  const [onConfirm, callStatus, setCallStatus] = useCallStatus(
    httpsCallable(functions, 'sessionMng-createSession'),
    createSessionArg,
    () => {
      setOpen(false);
    }
  );
  const goBack = () => {
    if (formStep === 0) {
      setOpen(false);
    } else {
      stepBack();
    }
  };

  return (
    <Dialog open={open} fullScreen>
      <CallStatusDialog
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        successMsg="Sessione creata correttamente"
        onClose={() => {
          resetStep();
        }}
      />
      <Box
        margin={2}
        pb={3}
        display="flex"
        flexDirection="column"
        gap={2}
        height="100%"
        alignItems="center"
        position="relative"
      >
        <IconButton
          onClick={goBack}
          color="primary"
          sx={{ position: 'absolute', left: 0, top: 0 }}
        >
          <ArrowBack fontSize="large" />
        </IconButton>
        {
          [
            <SessionInfoForm
              onConfirm={(
                id,
                description,
                groupAmount,
                includeJudges,
                includeRappr,
                liveRankCred
              ) => {
                setSessionInitData({
                  ...sessionInitData,
                  id,
                  description,
                  groupAmount,
                  includeJudges,
                  includeRappr,
                  liveRankCred,
                });
                setGroups(
                  arrOfLen(Number(groupAmount)).map(() => ({
                    name: '',
                    talents: [] as ITalent[],
                  }))
                );
                stepForward();
              }}
              existingIds={existingIds}
            />,
            <GroupNamer
              groupAmount={groupAmount}
              onConfirm={newGroupsNames => {
                setGroups(newGroupsNames.map(name => ({ name, talents: [] })));
                stepForward();
              }}
            />,
            <VotingSchemeSelector
              onConfirm={(newVotingScheme, newSongsPerTalent) => {
                setVotingScheme(newVotingScheme);
                setSongsPerTalent(newSongsPerTalent);
                stepForward();
              }}
            />,
            <ActivationTypeSelector
              onConfirm={newActivateInfos => {
                setActivateInfo(newActivateInfos);
                stepForward();
              }}
            />,
            <SessionParticipantsPicker
              onConfirm={(selTals, selJudges) => {
                stepForward();
                setGroups(
                  groups.map((group, groupIdx) => ({
                    ...group,
                    talents: selTals[groupIdx],
                  }))
                );

                setJudges(
                  selJudges.map(judName => ({
                    name: judName,
                    percentage: 1 / selJudges.length,
                  }))
                );
              }}
              includeJudges={includeJudges}
              songsPerTalent={songsPerTalent ?? 0}
              groupNames={groups.map(group => group.name)}
            />,
            // TODO think about what to do with this
            // ...(votingScheme === VotingScheme.selectSongs
            //   ? [
            //       <SongsPerTalentPicker
            //         songsPerTalent={songsPerTalent}
            //         groups={groups}
            //         onConfirm={newSongsPerGroup => {
            //           setGroups(
            //             groups.map((group, groupIdx) => ({
            //               ...group,
            //               talents: group.talents.map((tal, talIdx) => ({
            //                 ...tal,
            //                 songs: newSongsPerGroup[groupIdx][talIdx],
            //               })),
            //             }))
            //           );
            //           stepForward();
            //         }}
            //       />,
            //     ]
            //   : []),
            <TalentOrderSelector
              groups={groups}
              onConfirm={newTalentOrder => {
                setgroupTalentOrder(newTalentOrder);
                stepForward();
              }}
              onBack={goBack}
            />,
            ...(judges.length === 1 || !includeJudges
              ? []
              : [
                  <JudgePercentageSelect
                    judges={judges}
                    onConfirm={newJudges => {
                      setJudges(newJudges);
                      stepForward();
                    }}
                    onBack={goBack}
                  />,
                ]),
            <CreateSessionRecap
              groups={groups}
              judges={judges}
              sessionId={id}
              sessionDescription={description}
              activateInfos={activateInfos}
              onConfirm={onConfirm}
              votingScheme={votingScheme}
              songsPerTalent={songsPerTalent}
              groupTalentOrder={groupTalentOrder}
            />,
          ][formStep]
        }
      </Box>
    </Dialog>
  );
}

export default CreateSessionForm;
