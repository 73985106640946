import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from '@mui/icons-material/Edit';
import CustomButton from '../misc/CustomButton';
import { useEffect, useState } from 'react';
import { functions } from '../../fbConfig';
import {
  ISession,
  ESessionOpenTarget,
  IDeleteSessionData,
  ESessionActivationType,
  ISchedule,
} from '../../interfaces';
import SessionRecap from '../SessionRecap';
import SessionVoteRecap from '../SessionVoteRecap';
import { httpsCallable } from 'firebase/functions';
import useTimeTrigger from '../../hooks/useTimeTrigger';
import { isNowWithinSchedule } from './SessionsTable';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import GiftcardIcon from '@mui/icons-material/CardGiftcard';
import GiftTicketsManager from './GiftTicketsManager';
import ReserveTicketsManager from '../ReserveTicketsManager';
import EditSession from './EditSession';

type DIALOG_TYPE = 'votes' | 'info' | 'tickets' | 'giftTickets' | 'edit';

const handleDelete = (sessionId: string) => {
  const deleteSessionArgs: IDeleteSessionData = {
    targetSessionId: sessionId,
  };
  const deleteSession = httpsCallable(functions, 'sessionMng-deleteSession');

  deleteSession(deleteSessionArgs)
    .then(res => {
      console.info('Session deleted successfully', res);
    })
    .catch(err => {
      console.error('ERROR WHEN DELETING SESSION', err);
    });
};

interface ISessionActionsProps {
  session: ISession;
  onFavoriteClick: () => void;
  isFavorite: boolean;
}

function SessionActions(props: ISessionActionsProps) {
  const { session, onFavoriteClick, isFavorite } = props;
  const {
    id,
    groups,
    judges,
    description,
    votingScheme,
    songsPerTalent,
    groupTalentOrder,
  } = session;

  const [dialogType, setDialogType] = useState<DIALOG_TYPE | null>(null);

  const [triggerNow, triggerAt] = useTimeTrigger();

  useEffect(() => {
    const minFutureDateTime = Math.min(
      ...Object.values(ESessionOpenTarget)
        .flatMap(openTarget =>
          session[openTarget].type === ESessionActivationType.onSchedule
            ? [
                (session[openTarget].open as ISchedule).start,
                (session[openTarget].open as ISchedule).end,
              ]
            : []
        )
        .filter(time => time > Date.now() / 1000)
    );

    if (minFutureDateTime !== Infinity) {
      const clearTimer = triggerAt(minFutureDateTime);
      return () => {
        clearTimer();
      };
    }
  }, [session, triggerAt]);

  return (
    <>
      <Dialog
        open={dialogType !== null}
        onClose={() => {
          setDialogType(null);
        }}
        fullWidth
        maxWidth={'lg'}
      >
        <Box m={2}>
          {dialogType === 'votes' ? (
            <SessionVoteRecap
              sessionId={id}
              groups={groups}
              judgesAmount={(judges ?? []).length}
            />
          ) : dialogType === 'info' ? (
            <SessionRecap
              groups={groups}
              judges={judges}
              id={id}
              description={description}
              showCreds={true}
              votingScheme={votingScheme}
              songsPerTalent={songsPerTalent}
              groupTalentOrder={groupTalentOrder}
            />
          ) : dialogType === 'tickets' ? (
            <ReserveTicketsManager sessionId={id} />
          ) : dialogType === 'edit' ? (
            <EditSession
              sessionId={id}
              groups={groups}
              songsPerTalent={songsPerTalent ?? 0}
            />
          ) : (
            <GiftTicketsManager sessionId={id} />
          )}
        </Box>
      </Dialog>
      <CustomButton
        sxColor="primary.main"
        onClick={onFavoriteClick}
        useIconButton
        toolTipTitle="Aggiungi a preferiti"
      >
        {isFavorite ? <StarIcon /> : <StarBorderIcon />}
      </CustomButton>
      <CustomButton
        sxColor="primary.main"
        onClick={() => {
          setDialogType('votes');
        }}
        useIconButton
        toolTipTitle="Vedi voti"
      >
        <EqualizerIcon />
      </CustomButton>
      <CustomButton
        useIconButton
        toolTipTitle="Vedi dettagli votazione"
        sxColor="primary.main"
        onClick={() => {
          setDialogType('info');
        }}
      >
        <InfoIcon />
      </CustomButton>
      <CustomButton
        useIconButton
        toolTipTitle="Gestisci biglietti"
        sxColor="primary.main"
        onClick={() => {
          setDialogType('tickets');
        }}
      >
        <TicketIcon />
      </CustomButton>
      <CustomButton
        useIconButton
        toolTipTitle="Gestisci accrediti"
        sxColor="primary.main"
        onClick={() => {
          setDialogType('giftTickets');
        }}
      >
        <GiftcardIcon />
      </CustomButton>
      <CustomButton
        useIconButton
        toolTipTitle="Modifica sessione"
        sxColor="primary.main"
        onClick={() => {
          setDialogType('edit');
        }}
      >
        <EditIcon />
      </CustomButton>
      <CustomButton
        useIconButton
        shouldAskConfirm
        confirmMsg={`Confermi di voler cancellare la votazione ${id}`}
        sxColor="primary.main"
        onClick={() => {
          handleDelete(id);
        }}
        disabled={Object.values(ESessionOpenTarget).some(openTarget =>
          session[openTarget].type === ESessionActivationType.onPrompt
            ? session[openTarget].open
            : isNowWithinSchedule(
                session[openTarget].open as ISchedule,
                triggerNow
              )
        )}
      >
        <DeleteIcon />
      </CustomButton>
    </>
  );
}

export default SessionActions;
