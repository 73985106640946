import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { useState, Fragment, useEffect } from 'react';
import { db } from '../fbConfig';
import { ERequestStatus } from '../interfaces';
import GetAppIcon from '@mui/icons-material/GetApp';
import { SUCCESS_COLOR, ERROR_COLOR, SESSIONS_COLL_ID } from '../constants';
import CustomButton from './misc/CustomButton';
import { collection, setDoc } from 'firebase/firestore';
import { doc, onSnapshot } from 'firebase/firestore';
import { ITicketRequest } from '../interfaces';

interface IReserveTicketsManagerProps {
  sessionId: string;
}

function ReserveTicketsManager(props: IReserveTicketsManagerProps) {
  const { sessionId } = props;

  const [reservedTickets, setReservedTickets] = useState<ITicketRequest[]>([]);
  const [csvUrl, setCsvUrl] = useState('');

  useEffect(() => {
    if (reservedTickets !== undefined) {
      const csvRows = ['Nome,Cognome,Telefono,Stato'];

      Object.values(ERequestStatus).forEach(status => {
        reservedTickets
          .filter(req => req.status === status)
          .forEach(({ firstName, lastName, phone }) => {
            csvRows.push([firstName, lastName, phone].join(','));
          });
      });

      const text = csvRows.join('\n');
      const data = new Blob([text], { type: 'text/plain' });

      const url = window.URL.createObjectURL(data);
      setCsvUrl(url);
    }
  }, [reservedTickets]);

  useEffect(() => {
    const clearOnSnapShot = onSnapshot(
      collection(db, SESSIONS_COLL_ID, sessionId, 'reserved-tickets'),
      giftTickestSnap => {
        const newGiftTickets = giftTickestSnap.docs.map(doc => ({
          ...doc.data(),
          phone: doc.id,
        })) as ITicketRequest[];
        setReservedTickets(newGiftTickets);
      },
      err => {
        console.error('ERROR WHEN LISTENING TO GIFT TICKETS', err);
      }
    );
    return () => {
      clearOnSnapShot();
    };
  }, [sessionId]);

  const acceptRequest = ({ phone }: ITicketRequest) => {
    const reqToAccDocRef = doc(
      db,
      SESSIONS_COLL_ID,
      sessionId,
      'reserved-tickets',
      phone
    );
    setDoc(reqToAccDocRef, { status: ERequestStatus.accepted }, { merge: true })
      .then(() => {
        console.info('Request succefully accepted');
      })
      .catch(err => {
        console.error('ERROR WHEN ACCEPTING A REQUEST', err);
      });
  };

  const rejectRequest = ({ phone }: ITicketRequest) => {
    const reqToAccDocRef = doc(
      db,
      SESSIONS_COLL_ID,
      sessionId,
      'reserved-tickets',
      phone
    );
    setDoc(reqToAccDocRef, { status: ERequestStatus.accepted }, { merge: true })
      .then(() => {
        console.info('Request succefully refused');
      })
      .catch(err => {
        console.error('ERROR WHEN REFUSING A REQUEST', err);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#FFF',
      }}
    >
      <a
        id="download_csv"
        download={`genVision_prenotazioni.csv`}
        href={csvUrl}
      >
        <GetAppIcon fontSize="large" />
      </a>
      {Object.values(ERequestStatus).map(status => (
        <Fragment key={status}>
          {reservedTickets
            .filter(req => req.status === status)
            .map(req => (
              <div
                key={req.phone}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '15px auto',
                }}
              >
                <p
                  style={{
                    fontSize: '1rem',
                    textDecoration:
                      req.status === 'rejected' ? 'line-through' : 'none',
                    color:
                      req.status === 'accepted'
                        ? SUCCESS_COLOR
                        : req.status === 'pending'
                        ? '#e2cf42'
                        : ERROR_COLOR,
                  }}
                >
                  <>
                    {req.firstName} {req.lastName}
                  </>
                  <br />
                  <i>{req.phone}</i>
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {req.status === 'pending' && (
                    <>
                      <CustomButton
                        useIconButton
                        onClick={() => rejectRequest(req)}
                        style={{ margin: '0 4px' }}
                      >
                        <Clear />
                      </CustomButton>
                      <CustomButton
                        useIconButton
                        onClick={() => acceptRequest(req)}
                        style={{ margin: '0 4px' }}
                      >
                        <Check />
                      </CustomButton>
                    </>
                  )}
                  {req.status === 'accepted' && (
                    <>
                      <Check
                        fontSize="large"
                        style={{ color: SUCCESS_COLOR }}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}
        </Fragment>
      ))}
    </div>
  );
}

export default ReserveTicketsManager;
