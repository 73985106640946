import Button from '@mui/material/Button';
import { httpsCallable } from 'firebase/functions';
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  IComputeLiveRankData,
  IAudRankData,
  IGetAudRankData,
  IGroup,
  TJuryVotesDoc,
  TRapprVoteDoc,
  ITalentRank,
  ILiveRankDoc,
} from '../interfaces';
import { db, functions } from '../fbConfig';
import { Typography } from '@mui/material';
import TextTable from './TextTable';
import { doc, onSnapshot } from 'firebase/firestore';
import useCallStatus from '../hooks/useCallStatus';
import CallStatusDialog from './misc/CallStatusDialog';
import AccordionList from './Accordion';
import {
  ADD_INFO_COLLECTION_ID,
  RAPPR_VOTES_DOC_ID,
  SESSIONS_COLL_ID,
} from '../constants';
import { arrOfLen } from '../utils';
import Box from '@mui/system/Box';

interface ISessionVoteRecapProps {
  sessionId: string;
  groups: IGroup[];
  judgesAmount: number;
}

function SessionVoteRecap(props: ISessionVoteRecapProps) {
  const { sessionId, groups, judgesAmount } = props;
  const [audRank, setAudRank] = useState<
    { [talId: string]: ITalentRank } | undefined
  >(undefined);
  const [totalVotes, setTotalVotes] = useState<undefined | number>(undefined);

  const [juryVotes, setJuryVotes] = useState<undefined | TJuryVotesDoc>(
    undefined
  );
  const [liveRank, setLiveRank] = useState<undefined | ILiveRankDoc>(undefined);
  const [rapprVotes, setRapprVotes] = useState<undefined | TRapprVoteDoc>(
    undefined
  );

  const TAL_ID_TO_SCHOOL_NAME = useMemo(
    () =>
      groups
        .flatMap(group => group.talents)
        .reduce(
          (acc, val) => ({ ...acc, [val.id]: val.schoolName }),
          {} as { [talId: string]: string }
        ),
    [groups]
  );

  const TAL_ID_TO_NAME = useMemo(
    () =>
      groups
        .flatMap(group => group.talents)
        .reduce(
          (acc, val) => ({ ...acc, [val.id]: val.name }),
          {} as { [talId: string]: string }
        ),
    [groups]
  );

  const SONG_ID_TO_SONG_NAME = useMemo(
    () =>
      groups
        .flatMap(group =>
          group.talents.flatMap(
            tal => tal.songs?.map(({ id, name }) => ({ id, name })) ?? []
          )
        )
        .reduce(
          (acc, val) => ({ ...acc, [val.id]: val.name }),
          {} as { [songId: string]: string }
        ),
    [groups]
  );

  const [computeLiveRank, callStatus, setCallStatus] = useCallStatus(
    httpsCallable(functions, 'rank-computeLiveRank'),
    { targetSessionId: sessionId } as IComputeLiveRankData
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAudRankArgs: IGetAudRankData = { targetSessionId: sessionId };
    const getAudRank = httpsCallable(functions, 'rank-getAudienceRank');
    setLoading(true);
    getAudRank(getAudRankArgs)
      .then(res => {
        setLoading(false);
        setAudRank((res.data as IAudRankData).audRank);
        setTotalVotes((res.data as IAudRankData).totalVotes);
      })
      .catch(err => {
        setLoading(false);
        console.error('ERROR WHEN GETTING FINAL RANK', err);
      });

    const clearSnapshotLiveRank = onSnapshot(
      doc(db, SESSIONS_COLL_ID, sessionId, ADD_INFO_COLLECTION_ID, 'liveRank'),
      snap => {
        setLiveRank(snap.data() as ILiveRankDoc);
      },
      err => {
        console.error('ERROR WHEN GETTING LIVE RANK', err);
      }
    );

    const clearSnapshotJury = onSnapshot(
      doc(db, SESSIONS_COLL_ID, sessionId, ADD_INFO_COLLECTION_ID, 'juryVotes'),
      snap => {
        setJuryVotes(snap.data() as TJuryVotesDoc);
      },
      err => {
        console.error('ERROR WHEN LISTING TO JURY VOTES', err);
      }
    );

    const clearSnapshotRappr = onSnapshot(
      doc(
        db,
        SESSIONS_COLL_ID,
        sessionId,
        ADD_INFO_COLLECTION_ID,
        RAPPR_VOTES_DOC_ID
      ),
      snap => {
        setRapprVotes(snap.data() as TRapprVoteDoc);
      },
      err => {
        console.error('ERROR WHEN LISTING TO RAPPR VOTES', err);
      }
    );

    return () => {
      clearSnapshotJury();
      clearSnapshotRappr();
      clearSnapshotLiveRank();
    };
  }, [sessionId]);

  const maxSongsPerTal = Math.max(
    ...Object.keys(audRank ?? {}).map(
      talId => Object.keys(audRank?.[talId]?.songsVotes ?? {})?.length ?? 15
    )
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <AccordionList
        items={[
          {
            title: `Voti pubblico ${sessionId}`,
            content: audRank && (
              <>
                <Typography align="center">
                  <b>Voti totali: &nbsp; {totalVotes}</b>
                </Typography>
                <TextTable
                  columnTitles={[
                    'Id',
                    'Talento',
                    'Scuola',
                    'Voti',
                    ...arrOfLen(maxSongsPerTal).map(
                      (_, idx) => `Canzone ${idx + 1}`
                    ),
                  ]}
                  rows={Object.keys(audRank)
                    .sort((talIdA, talIdB) => talIdA.localeCompare(talIdB))
                    .map((talId, i) => [
                      talId,
                      TAL_ID_TO_NAME[talId],
                      TAL_ID_TO_SCHOOL_NAME[talId],
                      audRank[talId].totalVotes,
                      ...Object.keys(audRank[talId].songsVotes ?? {})
                        .map(songId => ({
                          name: SONG_ID_TO_SONG_NAME?.[songId] ?? '',
                          totalVotes: audRank[talId]?.songsVotes?.[songId] ?? 0,
                        }))
                        .sort((a, b) => b.totalVotes - a.totalVotes)
                        .map(
                          ({ name, totalVotes }) => `${totalVotes} - ${name}`
                        ),
                    ])}
                  downloadFileName={`Voti pubblico ${sessionId}`}
                />
              </>
            ),
            disabled: !audRank,
            loading,
          },
          {
            title: 'Voti giudici',
            content:
              juryVotes &&
              groups.map(group => (
                <Fragment key={group.name}>
                  <Typography align="center" my={1}>
                    {group.name} (Totale:{' '}
                    {Object.keys(juryVotes!).reduce(
                      (total, judge) =>
                        total +
                        Object.keys(juryVotes![judge]).reduce(
                          (totalByJudge, talId) =>
                            totalByJudge + juryVotes![judge][talId],
                          0
                        ),
                      0
                    )}
                    )
                  </Typography>
                  <TextTable
                    key={group.name}
                    columnTitles={[
                      'Scuola',
                      ...Object.keys(juryVotes),
                      'Totale',
                    ]}
                    rows={group.talents.map(({ id: talId }) => [
                      TAL_ID_TO_SCHOOL_NAME[talId],
                      ...Object.keys(juryVotes!).map(
                        judge => juryVotes![judge][talId]
                      ),
                      Object.keys(juryVotes!)
                        .map(judge => juryVotes![judge][talId])
                        .reduce((total, judgeVote) => total + judgeVote, 0),
                    ])}
                  />
                </Fragment>
              )),
            disabled: !juryVotes,
          },
          {
            title: 'Voti rappresentanti',
            content: rapprVotes && (
              <TextTable
                columnTitles={['Votante', 'Votata']}
                rows={Object.keys(rapprVotes).map(school => [
                  school,
                  TAL_ID_TO_SCHOOL_NAME[rapprVotes[school]],
                ])}
              />
            ),
            disabled: !rapprVotes,
          },
          {
            title: 'Classifica live - testo',
            content: liveRank && (
              <Typography component={'pre'}>{liveRank.text}</Typography>
            ),
            disabled: !liveRank,
          },
          {
            title: 'Classifica live - voti finali',
            content: liveRank && (
              <TextTable
                columnTitles={['Scuola', 'Voti']}
                rows={Object.keys(liveRank.finalVotes).map(schoolName => [
                  schoolName,
                  liveRank.finalVotes[schoolName],
                ])}
              />
            ),
            disabled: !liveRank,
          },
        ]}
      />
      <Button
        variant="contained"
        sx={{ mt: 1 }}
        onClick={computeLiveRank}
        disabled={Object.keys(juryVotes ?? {}).length !== judgesAmount}
      >
        Calcola classifica stile eurovision
      </Button>
      <CallStatusDialog
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        onClose={() => {
          setCallStatus('none');
        }}
        successMsg=""
      />
    </Box>
  );
}

export default SessionVoteRecap;
