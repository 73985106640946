import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { VotingScheme } from '../../interfaces';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

interface IVotingSchemeSelectorProps {
  onConfirm: (
    newVotingScheme: VotingScheme,
    songsPerTalent: number | undefined
  ) => void;
}

function VotingSchemeSelector(props: IVotingSchemeSelectorProps) {
  const { onConfirm } = props;
  const [votingScheme, setVotingScheme] = useState<VotingScheme>(
    VotingScheme.basic
  );
  const [songsPerTalent, setSongsPerTalent] = useState('');

  const isSongsPerTalentWrong = Number(songsPerTalent) <= 0;

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
    >
      <Typography variant="h5" my={1}>
        Seleziona la programmazione per la sessione
      </Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography align="right">Base</Typography>
        <Switch
          checked={votingScheme === 'selectSongs'}
          onChange={() => {
            setVotingScheme(
              votingScheme === VotingScheme.basic
                ? VotingScheme.selectSongs
                : VotingScheme.basic
            );
          }}
        />
        <Typography>Con scelta canzoni</Typography>
      </Box>
      {votingScheme === VotingScheme.selectSongs && (
        <TextField
          value={songsPerTalent}
          onChange={e => {
            setSongsPerTalent(e.target.value);
          }}
          required
          error={songsPerTalent !== '' && isSongsPerTalentWrong}
          helperText={
            songsPerTalent !== '' &&
            isSongsPerTalentWrong &&
            'Il numero delle canzoni deve essere maggiore di 0'
          }
          fullWidth
          id="numero-canzoni-per-talento"
          type="number"
          label="Numero canzoni per talento"
        />
      )}
      <Button
        variant="contained"
        onClick={() => {
          onConfirm(
            votingScheme,
            votingScheme === VotingScheme.selectSongs
              ? Number(songsPerTalent)
              : undefined
          );
        }}
        disabled={
          votingScheme === VotingScheme.selectSongs && isSongsPerTalentWrong
        }
      >
        Prosegui
      </Button>
    </Box>
  );
}

export default VotingSchemeSelector;
