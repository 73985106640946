import Box from '@mui/system/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { arrOfLen } from '../../utils';
import { useState } from 'react';

interface IGroupNamerProps {
  groupAmount: number;
  onConfirm: (groupNames: string[]) => void;
}

function GroupNamer(props: IGroupNamerProps) {
  const { groupAmount, onConfirm } = props;
  const [newGroups, setNewGroups] = useState(
    arrOfLen(groupAmount).map((_, i) => '')
  );
  // FIXME add check that names cannot be equal

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      <Typography my={2}>Inserisci un nome per ogni girone</Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        maxWidth={250}
        width="100%"
        mb={2}
      >
        {arrOfLen(groupAmount).map((_, i) => (
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            justifyContent="space-between"
            width="100%"
            key={i}
          >
            <Typography>{i + 1})</Typography>
            <TextField
              type="text"
              size="small"
              required
              sx={{ width: '200px' }}
              value={newGroups[i]}
              onChange={e => {
                const newMuls = [...newGroups];
                newMuls[i] = e.target.value;
                setNewGroups(newMuls);
              }}
              id={`group-name`}
            />
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          onConfirm(newGroups);
        }}
        disabled={newGroups.some(groupName => !groupName)}
      >
        Prosegui
      </Button>
    </Box>
  );
}

export default GroupNamer;
