export const arrOfLen = (len: number) => Array.apply(null, Array(len));

const URL_REGEX =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

export const isUrlWrong = (url: string) => url !== '' && !URL_REGEX.test(url);

export const triggerTextDownload = (text: string, fileName: string) => {
  const elm = document.createElement('a');
  elm.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  elm.setAttribute('download', fileName);

  elm.style.display = 'none';
  document.body.appendChild(elm);

  elm.click();

  document.body.removeChild(elm);
};

export const timestampStr = () =>
  `${new Date().getFullYear()}_${
    new Date().getMonth() + 1
  }_${new Date().getDate()}_${new Date().getHours()}_${new Date().getMinutes()}`;
