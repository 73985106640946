import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface IGroupSelectorProps {
  groupNames: string[];
  talSelPerGroup: number[];
  onChange: (newGroup: number) => void;
}

export default function GroupSelector(props: IGroupSelectorProps) {
  const { groupNames, onChange, talSelPerGroup } = props;
  const [selGroup, setSelGroup] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelGroup(newValue);
    onChange(newValue);
  };

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <Tabs
        value={selGroup}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {groupNames.map((name, i) => (
          <Tab label={`${name} (${talSelPerGroup[i]})`} key={i} />
        ))}
      </Tabs>
    </Box>
  );
}
