import { Box, Button } from '@mui/material';
import { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react';

interface IFileUploaderProps {
  setDataUrl: Dispatch<SetStateAction<string | undefined>>;
  accept: string;
  id: string;
  icon: ReactNode;
  text?: string;
}

export default function FileUploader(props: IFileUploaderProps) {
  const { setDataUrl, accept, id, icon, text } = props;
  const onFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files && ev.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setDataUrl(e?.target?.result as string);
      };

      reader.readAsDataURL(ev.target.files[0]);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <label htmlFor={id}>
        <Box sx={{ cursor: 'pointer' }}>
          <input
            onChange={onFileChange}
            style={{ display: 'none' }}
            id={id}
            accept={accept}
            type="file"
          />
          <Button
            color="primary"
            variant="outlined"
            sx={{ borderRadius: 20, width: '100%' }}
            onClick={() => {
              document.getElementById(id)?.click();
            }}
            endIcon={text !== undefined && icon}
          >
            {text ? text : icon}
          </Button>
        </Box>
      </label>
    </Box>
  );
}
